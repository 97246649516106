import React from "react";
import ReactDOM from "react-dom/client";
import "./styles/globals.scss";
import { MsalProvider } from "@azure/msal-react";
import axios from "axios";
import { AnimatePresence } from "framer-motion";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { toast } from "react-toastify";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { initialiseMsal } from "./services/msal";
import { UserProvider } from "./contexts";
import { getErrorMessage } from "./services/ErrorService";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
const instance = initialiseMsal();

axios.defaults.baseURL = process.env.REACT_APP_PUBLIC_BACKEND_API;

const handleExitComplete = () => {
    if (typeof window !== "undefined") {
        window.scrollTo({ top: 0 });
    }
};
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            suspense: false
        },
        mutations: {
            onError: (error) => {
                toast.error(getErrorMessage(error), { autoClose: 3000 });
            }
        }
    }
});
root.render(
    <React.StrictMode>
        <MsalProvider instance={instance}>
            <AnimatePresence
                mode="wait"
                onExitComplete={handleExitComplete}
            >
                <UserProvider>
                    <QueryClientProvider client={queryClient}>
                        <App />
                    </QueryClientProvider>
                </UserProvider>
            </AnimatePresence>
        </MsalProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
