import React from "react";
import {useUser} from "../../contexts";

type ButtonSignOutProps = {
    children: React.ReactNode;
};

export const ButtonSignOut = ({ children }: ButtonSignOutProps) => {
    const { logout } = useUser();

    return <button onClick={logout}>{children}</button>;
};
export default ButtonSignOut;
