import React, { useState } from "react";
import { toast } from "react-toastify";
import { Section } from "../../components/ui";
import Calendar from "./Calendar";
import TimeSlotForm from "./TimeSlotForm";
import { TimeSlot, TimeSlotPost } from "../../interfaces";
import useTimeSlots from "./useTimeSlots";
import { useUser } from "../../contexts";
import { ButtonSecondary } from "../../components/button";

const Timeslots = () => {
    const { user } = useUser();
    const {
        timeslots,
        create,
        update,
        selectSlot,
        currentSlot,
        unselectSlot,
        deleteSlot
    } = useTimeSlots(user?.id ?? -1);

    const [show, setShow] = useState(false);
    const [date, setDate] = useState<{ start: Date; end: Date }>({
        start: new Date(),
        end: new Date()
    });

    const createTimeslot = (timeslot: TimeSlotPost) => {
        create.mutate(timeslot, {
            onSuccess: () => {
                setShow(false);
                toast.success("Tijdslot vastgelegd");
            }
        });
    };

    const updateTimeslot = (timeslot: TimeSlot) => {
        update.mutate(timeslot, {
            onSuccess: () => {
                setShow(false);
                toast.success("Tijdslot aangepast");
            }
        });
    };

    const deleteTimeslot = (id: number) => {
        deleteSlot.mutate(id, {
            onSuccess: () => {
                setShow(false);
                toast.success("Tijdslot verwijderd");
            }
        });
    };

    const selectDate = (start: Date, end: Date) => {
        unselectSlot();
        setDate({ start, end });
        setShow(true);
    };

    return (
            <div className="container">
                <Section>
                    <div className="flex flex-wrap gap-2 justify-between items-end">
                        <h1>Mijn beschikbaarheden</h1>
                        <div>
                            <ButtonSecondary onClick={() => setShow(true)}>
                                Nieuw
                            </ButtonSecondary>
                        </div>
                    </div>

                    <div
                        className="flex flex-wrap-reverse md:flex-nowrap gap-4 justify-center flex-row-reverse lg:flex-row">
                        <Calendar
                            selectDate={(start: Date, end: Date) =>
                                selectDate(start, end)
                            }
                            timeslots={timeslots.data ?? []}
                            onSelectEvent={(id: number) => {
                                selectSlot(id);
                                setShow(true);
                            }}
                            update={(timeslot) => updateTimeslot(timeslot)}
                            currentSlot={currentSlot}
                            selectSlot={selectSlot}
                        />
                        {show && (
                            <TimeSlotForm
                                submit={createTimeslot}
                                update={updateTimeslot}
                                deleteSlot={deleteTimeslot}
                                close={() => {
                                    setShow(false);
                                    unselectSlot();
                                }}
                                startDate={date.start}
                                endDate={date.end}
                                timeslot={currentSlot}
                            />
                        )}
                    </div>
                </Section>
            </div>
    );
};

export default Timeslots;
