import { useNavigate } from "react-router-dom";

export function MenuItem({
    icon,
    children,
    position = "left",
    link = "#"
}: {
    icon?: JSX.Element;
    children: any;
    position?: string;
    link?: string;
}) {
    const navigate = useNavigate();

    return (
        <li
            onClick={() => navigate(link)}
            className={`opacity-90 hover:opacity-100 transition-opacity flex gap-3 items-center group cursor-pointer text-sm font-medium ${
                position === "right"
                    ? "md:justify-self-end md:flex-row-reverse"
                    : "justify-self-start "
            }`}
        >
            <div className="flex items-center justify-center w-5 h-5 fill-orange-500">
                {icon}
            </div>
            <span className="relative group-hover:-translate-y-1 transition-all">
                {children}
                <div
                    className={`bg-orange-500 w-2 group-hover:w-6 ${
                        position === "right" ? "md:right-0" : ""
                    } h-[3px] rounded-full absolute group-hover:opacity-90 opacity-0 transition-all`}
                />
            </span>
        </li>
    );
}
export default MenuItem;
