import React, { FC } from "react";
import { ButtonAnimation } from "./ButtonAnimation";

interface ButtonPrimaryProps {
    children?: React.ReactNode;
    className?: string;
    onClick?: () => void;
}

export const ButtonPrimary: FC<ButtonPrimaryProps> = ({
    children,
    className = "",
    onClick
}) => (
    <ButtonAnimation>
        <button className={`button-primary ${className}`} onClick={onClick}>
            {children}
        </button>
    </ButtonAnimation>
);
export default ButtonPrimary;
