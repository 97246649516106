import { FC, SVGProps } from "react";

interface IconEditProps extends SVGProps<SVGSVGElement> {}

const IconEdit: FC<IconEditProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="21"
        height="20"
        fill="#B9B4B4FF"
        viewBox="0 0 21 20"
        {...props}
    >
        <path d="m4.32 15.695 4.764-.016L19.485 5.377c.408-.408.633-.95.633-1.527 0-.576-.225-1.118-.633-1.526L17.773.61c-.817-.816-2.241-.812-3.05-.003L4.318 10.91v4.784ZM16.245 2.138l1.716 1.71-1.725 1.707-1.712-1.711 1.72-1.706Zm-9.767 9.674 6.511-6.45 1.713 1.713-6.51 6.447-1.714.006v-1.716Z" />
        <path d="M2.16 20h15.117c1.19 0 2.16-.969 2.16-2.16V8.48l-2.16 2.16v7.2H5.57c-.028 0-.058.011-.086.011-.035 0-.07-.01-.108-.01H2.16V2.722h7.393l2.16-2.16H2.16A2.166 2.166 0 0 0 0 2.724V17.84C0 19.031.969 20 2.16 20Z" />
    </svg>
);

export default IconEdit;
