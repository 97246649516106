import React, { FC } from "react";
import {
    ButtonNext,
    ButtonNextPage,
    ButtonPrevious,
    ButtonPreviousPage
} from "../button";

interface PaginationProps {
    totalPages: number;
    setPage: (page: (prev: number) => number) => void;
    page: number;
}
export const Pagination: FC<PaginationProps> = ({
    totalPages,
    setPage,
    page
}) => (
    <div className="flex justify-end">
        {page > 0 && (
            <div className="flex">
                <ButtonPrevious
                    disabled={false}
                    onClick={() => setPage((prev: number) => prev - 1)}
                />
                <ButtonPreviousPage
                    pageNumber={page}
                    onClick={() => setPage(() => page - 1)}
                    className="flex ml-2 bg-transparent hover:bg-gray-400 text-gray-400 font-semibold hover:text-white py-2 px-4 border border-gray-400 hover:border-transparent rounded"
                />
            </div>
        )}

        {page <= 0 && (
            <div className="flex">
                <ButtonPrevious
                    disabled
                    onClick={() => setPage((prev: number) => prev - 1)}
                />
            </div>
        )}

        <ButtonNextPage
            pageNumber={page + 1}
            onClick={() => setPage(() => page)}
            className="flex ml-2 bg-transparent hover:bg-orange-500 text-orange-500 font-semibold hover:text-white py-2 px-4 border border-orange-500 hover:border-transparent rounded"
        />
        {page < totalPages - 1 && (
            <div className="flex">
                <ButtonNextPage
                    pageNumber={page + 2}
                    onClick={() => setPage(() => page + 1)}
                    className="flex ml-2 bg-transparent hover:bg-gray-400 hover:text-white text-gray-400 font-semibold py-2 px-4 border border-gray-400 hover:border-transparent rounded"
                />
                <ButtonNext
                    disabled={false}
                    onClick={() => setPage((prev: number) => prev + 1)}
                />
            </div>
        )}
        {page >= totalPages - 1 && (
            <div className="flex">
                <ButtonNext
                    disabled
                    onClick={() => setPage((prev: number) => prev + 1)}
                />
            </div>
        )}
    </div>
);
export default Pagination;
