import { FC, SVGProps } from "react";

interface IconLocationProps extends SVGProps<SVGSVGElement> {}

const IconLocation: FC<IconLocationProps> = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M7.99998 1.3335C5.41998 1.3335 3.33331 3.42016 3.33331 6.00016C3.33331 7.16016 3.66665 8.24683 4.27331 9.22683C4.90665 10.2535 5.73998 11.1335 6.37998 12.1602C6.69331 12.6602 6.91998 13.1268 7.15998 13.6668C7.33331 14.0335 7.47331 14.6668 7.99998 14.6668C8.52665 14.6668 8.66665 14.0335 8.83331 13.6668C9.07998 13.1268 9.29998 12.6602 9.61331 12.1602C10.2533 11.1402 11.0866 10.2602 11.72 9.22683C12.3333 8.24683 12.6666 7.16016 12.6666 6.00016C12.6666 3.42016 10.58 1.3335 7.99998 1.3335V1.3335ZM7.99998 7.8335C7.07998 7.8335 6.33331 7.08683 6.33331 6.16683C6.33331 5.24683 7.07998 4.50016 7.99998 4.50016C8.91998 4.50016 9.66665 5.24683 9.66665 6.16683C9.66665 7.08683 8.91998 7.8335 7.99998 7.8335Z" />
    </svg>
);

export default IconLocation;
