import { useNavigate } from "react-router-dom";
import { useIsAuthenticated } from "@azure/msal-react";
import { Avatar } from "../avatar/Avatar";
import { Menu } from "../menu";
import { useUser } from "../../contexts";

export const Header = () => {
    const navigate = useNavigate();
    const { user } = useUser();
    const isAuthenticated = useIsAuthenticated();

    return (
        <header className="w-full bg-black/5 mb-12">
            <div className="w-full bg-white/90 ">
                <div className="flex justify-between py-4 container">
                    <div
                        className="grid grid-flow-col items-center gap-2 cursor-pointer"
                        onClick={() => navigate("/")}
                    >
                        <div className="text-xl font-extrabold text-orange-500">
                            PCP
                        </div>
                        <div className="text-black/30">|</div>
                        <div className="translate-y-px text-sm sm:text-base">
                            Performance Cycle Planner{" "}
                            <span className="text-xs hidden sm:inline">
                                by Axxes
                            </span>
                        </div>
                    </div>
                    {isAuthenticated && user && (
                        <Avatar
                            className="hidden sm:flex text-right flex-row-reverse"
                            person={user}
                        />
                    )}
                    {!isAuthenticated && <div>Niet ingelogd</div>}
                </div>
            </div>
            <Menu />
        </header>
    );
};
export default Header;
