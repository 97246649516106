import axios from "axios";
import { UpdateUser, User } from "../interfaces";
import { requestToken } from "./msal";

export const uploadCsv = async (file: File) => {
    const formData = new FormData();
    const request = await requestToken();

    formData.append("file", file, file.name);
    await axios.post(`ImportUser`, formData, {
        headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${request.accessToken}`
        }
    });
};

export const getUsers = async () => {
    const request = await requestToken();

    const response = await axios.get<User[]>("user/getUsers", {
        headers: {
            Authorization: `Bearer ${request.accessToken}`
        }
    });
    return response.data;
};

export const deleteUser = async (email: string) => {
    const request = await requestToken();

    const response = await axios.delete<User[]>("user/deleteUser", {
        headers: {
            Authorization: `Bearer ${request.accessToken}`
        },
        params: {
            email
        }
    });
    return response.data;
};

export const getUserByEmail = async (email: string) => {
    const token = await requestToken();
    const response = await axios.get<User>(`User/GetUserByEmail`, {
        params: {
            email
        },
        headers: {
            Authorization: `Bearer ${token.idToken}`
        }
    });

    return response.data;
};
export const updateUser = async (user: UpdateUser) => {
    const request = await requestToken();

    const response = await axios.put<User[]>("user/updateUser", user, {
        headers: {
            Authorization: `Bearer ${request.accessToken}`
        }
    });
    return response.data;
};
export const getUserWithPartners = async (email: string) => {
    const request = await requestToken();
    const response = await axios.get<User>("user/getUserWithPartners", {
        headers: {
            Authorization: `Bearer ${request.accessToken}`
        },
        params: {
            email
        }
    });
    return response.data;
};

export const getAllPartners = async () => {
    const request = await requestToken();
    const response = await axios.get<User[]>("user/getAllPartners", {
        headers: {
            Authorization: `Bearer ${request.accessToken}`
        }
    });
    return response.data;
};

export const getMe = async () => {
    const request = await requestToken();
    const response = await axios.get<User>("user/me", {
        headers: {
            Authorization: `Bearer ${request.accessToken}`
        }
    });
    return response.data;
};
