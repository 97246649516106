import { FC, SVGProps } from "react";

interface IconDeleteProps extends SVGProps<SVGSVGElement> {}

const IconDelete: FC<IconDeleteProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="22"
        viewBox="0 0 20 22"
        fill="#B9B4B4FF"
        {...props}
    >
        <path d="M5.946 8.108v9.19h1.622v-9.19H5.946Zm3.243 0v9.19h1.622v-9.19H9.189Zm3.243 0v9.19h1.622v-9.19h-1.622Zm1.622-6.486C14.054.27 13.724 0 12.432 0H7.027c-1.362 0-1.61.297-1.61 1.627l-.012 1.616H1.081C.281 3.243 0 3.508 0 4.324v.54c0 .839.281 1.082 1.081 1.082h.54V20c0 1.346.298 1.622 1.622 1.622h12.973c1.352 0 2.162-.255 2.162-1.622V5.946h.54c.817 0 1.082-.26 1.082-1.081v-.54c0-.8-.232-1.082-1.081-1.082h-4.865V1.622Zm-1.622 0v1.621H7.027V1.622h5.405ZM4.324 5.946h11.352v12.973H4.324V5.946Z" />
    </svg>
);

export default IconDelete;
