import { FC } from "react";
import { format } from "date-fns";
import { ButtonAnimation } from "../button";
import { IconCheckCircle } from "../icons";
import "moment/locale/nl";
import { TimeRange } from "../../interfaces";

interface TimeSlotProps {
    date?: TimeRange;
    onClick?: () => void;
    selected?: boolean;
}
export const TimeSlot: FC<TimeSlotProps> = ({
    date,
    onClick,
    selected = false
}) => (
    <ButtonAnimation>
        <div
            className="cursor-pointer bg-white py-3 px-4 rounded-md shadow-lg shadow-black/5 transition-all text-sm flex justify-between items-center group"
            onClick={onClick}
        >
            {date ? (
                <span className="flex space-x-1 items-center">
                    <span className="capitalize text-xs">
                        {format(new Date(date.begin), "d MMM")}
                    </span>
                    <span className="text-xs text-black/20">|</span>
                    <span className="font-semibold text-black/80">
                        {format(new Date(date.begin), "HH:mm")}
                    </span>
                    <span>-</span>
                    <span className="font-semibold text-black/80">
                        {format(new Date(date.end), "HH:mm")}
                    </span>
                </span>
            ) : (
                "Geen datum gevonden"
            )}
            <div className="rounded-full border-black/10 w-6 h-6 relative overflow-hidden flex items-center justify-center border-2">
                <div
                    className={`transition-all group-hover:opacity-100 ${
                        selected ? "opacity-100" : "opacity-0"
                    }`}
                >
                    <IconCheckCircle />
                </div>
            </div>
        </div>
    </ButtonAnimation>
);
export default TimeSlot;
