import React, { createRef, FC, useState } from "react";
import FullCalendar, { EventChangeArg } from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "../../styles/calendar.scss";
import { useMediaQuery } from "react-responsive";
import useCalendar from "./useCalendar";
import { TimeSlot } from "../../interfaces";

interface CalendarProps {
    timeslots: TimeSlot[];
    selectDate: (start: Date, end: Date) => void;
    onSelectEvent: (id: number) => void;
    update: (timeslot: TimeSlot) => void;
    currentSlot: TimeSlot | null;
    selectSlot: (id: number) => void;
}

const Calendar: FC<CalendarProps> = ({
                                         timeslots,
                                         selectDate,
                                         onSelectEvent,
                                         update,
                                         currentSlot,
                                         selectSlot
                                     }) => {
    const { DAY_NAMES } = useCalendar();
    const [eventId, setEventId] = useState("");
    const calendarRef = createRef<FullCalendar>();

    useMediaQuery({ query: "(max-width: 768px)" }, undefined, (match) => {
        match
            ? calendarRef.current?.getApi().changeView("timeGridDay")
            : calendarRef.current?.getApi().changeView("timeGridWeek");
    });

    const updateEvent = (arg: EventChangeArg) => {
        const { event } = arg;
        selectSlot(parseInt(event.id, 10));
        if (!event.end || !event.start || !currentSlot) return;
        update({
            ...currentSlot,
            start: event.start,
            end: event.end
        });
    };
    return (
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            weekends={false}
            initialView="timeGridWeek"
            allDaySlot={false}
            nowIndicator
            slotMinTime={{
                hour: 8
            }}
            slotMaxTime={{
                hour: 19
            }}
            height="auto"
            locale="nl"
            buttonText={{
                today: "Vandaag"
            }}
            dayHeaderContent={(arg) => DAY_NAMES[arg.date.getDay() - 1]}
            selectable
            selectOverlap={false}
            select={(arg) => selectDate(arg.start, arg.end)}
            unselectAuto
            eventClick={({ event }) => {
                onSelectEvent(parseInt(event.id, 10));
                setEventId(event.id);
            }}
            events={timeslots.map((slot) => ({
                id: slot.id.toString(),
                title: slot.location,
                start: slot.start,
                end: slot.end,
                classNames: slot.id.toString() === eventId ? ["selected"] : []
            }))}
            editable
            eventStartEditable
            eventDurationEditable
            eventOverlap={false}
            eventChange={updateEvent}
            eventInteractive
            validRange={{
                start: new Date()
            }}
            ref={calendarRef}
        />
    );
};

export default Calendar;
