import { useState } from "react";
import Calendar from "react-calendar";
import { AnimatePresence } from "framer-motion";
import { useForm } from "react-hook-form";
import { useQuery } from "@tanstack/react-query";
import { Section } from "../components/ui";
import { Card } from "../components/card";
import { useMeeting } from "../shared/hooks/useMeeting";
import { ButtonRound } from "../components/button";
import { getUserWithPartners } from "../services/UserService";
import { useUser } from "../contexts";

function EditMeeting() {
    const { newMeeting, selectedDate, setSelectedDate } = useMeeting();
    const { user } = useUser();

    const [selectedTab, setSelectedTab] = useState("recap");
    const tabs = ["recap", "loon", "datum"];
    const { data: userWithPartners } = useQuery(
        ["userWithPartners", user?.email],
        () =>
            getUserWithPartners(user ? user.email : "maaike.vanhecke@axxes.com")
    );
    const meetingLocations: string[] = ["Gent", "Antwerpen", "Brussel"];

    const {
        register,
        formState: { errors }
    } = useForm({ mode: "onBlur" });

    // const onFormSubmit = async (data: any) => {
    //     const dataobj: any = [
    //         {
    //             meetingId: "",
    //             location: data.recap.location,
    //             startTime: selectedSlot?.slot.start,
    //             endTime: selectedSlot?.slot.end
    //         },
    //         {
    //             meetingId: "",
    //             location: data.loon.location,
    //             startTime: selectedSlot?.slot.start,
    //             endTime: selectedSlot?.slot.end
    //         }
    //     ];

    // dataobj["recap"].slot.start = selectedSlot?.slot.start;

    // dataobj[0].startTime = selectedSlot?.slot.start;
    // };

    const newMeetingOptions = {
        location: { required: "Locatie is verplicht" },
        hr: { required: "HR Business partner is verplicht" },
        league: {
            required: "League coach is verplicht"
        },
        captain: { required: "Team captain is verplicht" },
        other: {}
    };

    // useEffect(() => {
    //   console.log("slot", selectedSlot);
    // }, [selectedSlot]);

    // useEffect(() => {
    //   console.log(selectedDate);
    // }, [selectedDate]);

    // const [meetingIds] = useState<{
    //     recap?: string;
    //     loon?: string;
    // }>({
    //     recap: "",
    //     loon: ""
    // });

    // useEffect(() => {
    //     if (meetingId) {
    //         if (meetingId[0] && meetingId[1])
    //             setMeetingIds({ recap: meetingId[0], loon: meetingId[1] });
    //         else if (meetingId[0]) setMeetingIds({ recap: meetingId[1] });
    //     }
    // }, [meetingId]);

    return (
            <div className="container">
                <Section>
                    <h1>Voeg meeting toe</h1>
                    <div
                        className="shadow-sm grid grid-cols-3 items-center justify-around input p-1 font-medium rounded-full max-w-sm"
                        // onMouseLeave={() => setFocused(null)}
                    >
                        {tabs.map((item: string) => (
                            <div
                                className="relative py-2 w-auto cursor-pointer text-center capitalize"
                                key={item}
                                onClick={() => setSelectedTab(item)}
                                onKeyDown={(event: { key: string }) =>
                                    event.key === "Enter"
                                        ? setSelectedTab(item)
                                        : null
                                }
                                // onFocus={() => setFocused(item)}
                                // onMouseEnter={() => setFocused(item)}
                                tabIndex={0}
                            >
                                <span>{item}</span>
                                {/* {focused === item && ( */}
                                {/*    <motion.div */}
                                {/*        transition={{ */}
                                {/*            layout: { */}
                                {/*                duration: 0.2, */}
                                {/*                ease: "easeOut", */}
                                {/*            }, */}
                                {/*        }} */}
                                {/*        className="absolute bg-black/5 rounded-full h-full w-full top-0" */}
                                {/*        layoutId="highlight" */}
                                {/*    /> */}
                                {/* )} */}
                                {/* {selectedTab === item && ( */}
                                {/*    <motion.div */}
                                {/*        className="absolute bottom-0 left-[38%] rounded-full bg-orange-500/90 w-1/4 h-[3px]" */}
                                {/*        layoutId="underline" */}
                                {/*    /> */}
                                {/* )} */}
                            </div>
                        ))}
                    </div>

                    <form
                        className="tabs grid gap-4"
                        // onSubmit={handleSubmit(onFormSubmit, onErrors)}
                    >
                        <AnimatePresence>
                            {errors && (
                                <div className="grid">
                                    {/* <AnimatePresence> */}
                                    {/*    {errors?.map((key: string) => ( */}
                                    {/*        <SlideFromTop key={key}> */}
                                    {/*            <FadeIn> */}
                                    {/*                <AnimatePresence> */}
                                    {/*                    {Object.keys( */}
                                    {/*                        errors[key] */}
                                    {/*                    ).map( */}
                                    {/*                        ( */}
                                    {/*                            keyError: string */}
                                    {/*                        ) => ( */}
                                    {/*                            <SlideFromTop */}
                                    {/*                                key={ */}
                                    {/*                                    keyError */}
                                    {/*                                } */}
                                    {/*                            > */}
                                    {/*                                <FadeIn> */}
                                    {/*                                    <Alert */}
                                    {/*                                        className="mt-2" */}
                                    {/*                                        type={ */}
                                    {/*                                            key */}
                                    {/*                                        } */}
                                    {/*                                    > */}
                                    {/*                                        /!* @ts-ignore *!/ */}
                                    {/*                                        { */}
                                    {/*                                            errors[ */}
                                    {/*                                                key */}
                                    {/*                                            ][ */}
                                    {/*                                                keyError */}
                                    {/*                                            ] */}
                                    {/*                                                .message */}
                                    {/*                                        } */}
                                    {/*                                    </Alert> */}
                                    {/*                                </FadeIn> */}
                                    {/*                            </SlideFromTop> */}
                                    {/*                        ) */}
                                    {/*                    )} */}
                                    {/*                </AnimatePresence> */}
                                    {/*            </FadeIn> */}
                                    {/*        </SlideFromTop> */}
                                    {/*    ))} */}
                                    {/* </AnimatePresence> */}
                                </div>
                            )}
                        </AnimatePresence>

                        {selectedTab === "recap" && (
                            <div className="grid gap-4">
                                <div className="form">
                                    <div>
                                        <label className="label">Gesprek</label>
                                        <select
                                            className="input"
                                            disabled
                                        >
                                            <option value="recap">
                                                Recapgesprek
                                            </option>
                                            <option value="loon">
                                                Loongesprek
                                            </option>
                                        </select>
                                    </div>

                                    <div>
                                        <label className="label">
                                            Location
                                        </label>
                                        <select
                                            className="input"
                                            {...register(
                                                "recap.location",
                                                newMeetingOptions.location
                                            )}
                                        >
                                            {meetingLocations &&
                                                meetingLocations.map(
                                                    (meetingLocation) => (
                                                        <option
                                                            value={
                                                                meetingLocation
                                                            }
                                                            key={
                                                                meetingLocation
                                                            }
                                                        >
                                                            {meetingLocation}
                                                        </option>
                                                    )
                                                )}
                                            <option value="other">
                                                Andere..
                                            </option>
                                        </select>
                                        {newMeeting &&
                                            newMeeting[0] &&
                                            newMeeting[0].location ===
                                                "other" && (
                                                <input
                                                    {...register(
                                                        "recap.location",
                                                        newMeetingOptions.location
                                                    )}
                                                    className="input"
                                                    placeholder="Andere locatie.."
                                                />
                                            )}
                                    </div>
                                    <div>
                                        <label className="label">
                                            HR Business partner
                                        </label>
                                        {/* <input
                      className={`input ${
                        // @ts-ignore
                        errors?.recap?.hr?.message ? "border-orange-500" : ""
                      }`}
                      placeholder="HR Business partner.."
                      // value={"Toon"}
                      disabled={true}
                      // {...register("recap.hr", newMeetingOptions.hr)}
                    /> */}
                                        <input
                                            className="input"
                                            placeholder="HR Business partner.."
                                            // value={"Toon"}
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <label className="label">
                                            League coach
                                        </label>
                                        <input
                                            className="input"
                                            placeholder="League coach.."
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <label className="label">
                                            Team captain
                                        </label>
                                        <input
                                            className="input"
                                            placeholder="Team captain.."
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <label className="label">
                                            Ander persoon
                                        </label>
                                        <input
                                            className="input"
                                            placeholder="Ander persoon.."
                                            disabled
                                        />
                                    </div>
                                    <div className="text-xs">
                                        * Consultants kunnen geen leden
                                        aanpassen of verwijderen.
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <ButtonRound
                                        onClick={() => setSelectedTab("loon")}
                                    >
                                        Voeg recapgesprek toe
                                    </ButtonRound>
                                </div>
                            </div>
                        )}

                        {selectedTab === "loon" && (
                            <div className="grid gap-4">
                                <div className="form">
                                    <div>
                                        <label className="label">Gesprek</label>
                                        <select
                                            className="input"
                                            defaultValue="loon"
                                            disabled
                                        >
                                            <option value="recap">
                                                Recapgesprek
                                            </option>
                                            <option value="loon">
                                                Loongesprek
                                            </option>
                                        </select>
                                    </div>

                                    <div>
                                        <label className="label">
                                            Location
                                        </label>
                                        <select
                                            className="input"
                                            {...register(
                                                "loon.location",
                                                newMeetingOptions.location
                                            )}
                                        >
                                            {meetingLocations &&
                                                meetingLocations.map(
                                                    (meetingLocation) => (
                                                        <option
                                                            value={
                                                                meetingLocation
                                                            }
                                                            key={
                                                                meetingLocation
                                                            }
                                                        >
                                                            {meetingLocation}
                                                        </option>
                                                    )
                                                )}
                                            <option value="other">
                                                Andere..
                                            </option>
                                        </select>
                                        {newMeeting &&
                                            newMeeting[0] &&
                                            newMeeting[0].location ===
                                                "other" && (
                                                <input
                                                    {...register(
                                                        "loon.location",
                                                        newMeetingOptions.location
                                                    )}
                                                    className="input"
                                                    placeholder="Andere locatie.."
                                                />
                                            )}
                                    </div>
                                    <div>
                                        <label className="label">
                                            HR Business partner
                                        </label>
                                        {/* <input
                      className={`input ${
                        // @ts-ignore
                        errors?.loon?.hr?.message ? "border-orange-500" : ""
                      }`}
                      placeholder="HR Business partner.."
                      // value={"Toon"}
                      disabled={true}
                      // {...register("loon.hr", newMeetingOptions.hr)}
                    /> */}
                                        <input
                                            className="input"
                                            placeholder="HR Business partner.."
                                            // value={"Toon"}
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <label className="label">
                                            League coach
                                        </label>
                                        <input
                                            className="input"
                                            placeholder="League coach.."
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <label className="label">
                                            Team captain
                                        </label>
                                        <input
                                            className="input"
                                            placeholder="Team captain.."
                                            disabled
                                        />
                                    </div>
                                    <div>
                                        <label className="label">
                                            Ander persoon
                                        </label>
                                        <input
                                            className="input"
                                            placeholder="Ander persoon.."
                                            disabled
                                        />
                                    </div>
                                    <div className="text-xs">
                                        * Consultants kunnen geen leden
                                        aanpassen of verwijderen.
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <ButtonRound
                                        onClick={() => setSelectedTab("datum")}
                                    >
                                        Voeg loongesprek toe
                                    </ButtonRound>
                                </div>
                            </div>
                        )}

                        {selectedTab === "datum" && (
                            <div className="grid gap-4">
                                <div className="grid gap-4 md:grid-flow-col">
                                    <Card
                                        onClick={() => setSelectedTab("recap")}
                                        attendees={userWithPartners?.recapMeetingPartners?.map(
                                            (p) => p.user
                                        )}
                                        type="recapgesprek"
                                        clickable={false}
                                        timeslot={null}
                                    />
                                    <Card
                                        onClick={() => setSelectedTab("loon")}
                                        attendees={userWithPartners?.salaryMeetingPartners?.map(
                                            (p) => p.user
                                        )}
                                        type="loongesprek"
                                        clickable={false}
                                        timeslot={null}
                                    />
                                </div>
                                <div className="grid gap-4 md:grid-cols-2 items-start mt-4">
                                    <div>
                                        <h3>Kies datum</h3>
                                        <Calendar
                                            className="card mt-4"
                                            onChange={setSelectedDate}
                                            value={selectedDate}
                                            tileClassName={({ date, view }) =>
                                                view === "month" &&
                                                (date.getDay() === 6 ||
                                                    date.getDay() === 0)
                                                    ? "bg-red-100 text-black/40 cursor-not-allowed"
                                                    : date.getDate() + 1 ===
                                                      new Date().getDate()
                                                    ? "bg-black/10"
                                                    : "text-black/20 "
                                            }
                                            tileDisabled={({ date, view }) =>
                                                view === "month" &&
                                                (date.getDay() === 6 ||
                                                    date.getDay() === 0)
                                            }
                                            next2Label={null}
                                            prev2Label={null}
                                        />
                                    </div>
                                    <div>
                                        <div className="grid gap-4">
                                            <div className="grid gap-2 mt-4">
                                                <h4 className="h4 ">
                                                    {/* <span> */}
                                                    {/*  Beschikbare slots{" "} */}
                                                    {/*    <span className="ml-1 text-xs font-normal"> */}
                                                    {/*    voor {moment(selectedDate).format("dddd LL")} */}
                                                    {/*  </span> */}
                                                    {/* </span> */}
                                                    {/* <div className="badge">1/2</div> */}
                                                </h4>
                                                {/*                      <div className="grid gap-2"> */}
                                                {/*                          {availableSlots */}
                                                {/*                              ? availableSlots.map( */}
                                                {/*                                  ( */}
                                                {/*                                      availableSlot: TimeSlotInterface, */}
                                                {/*                                      index: number */}
                                                {/*                                  ) => { */}
                                                {/*                                      return ( */}
                                                {/*                                          <TimeSlot */}
                                                {/*                                              key={index} */}
                                                {/*                                              date={availableSlot} */}
                                                {/*                                              onClick={() => */}
                                                {/*                                                  setSelectedSlot({ */}
                                                {/*                                                      date: new Date(), */}
                                                {/*                                                      slot: { */}
                                                {/*                                                          start: new Date(), */}
                                                {/*                                                          end: new Date(), */}
                                                {/*                                                      }, */}
                                                {/*                                                  }) */}
                                                {/*                                              } */}
                                                {/*                                              selected={ */}
                                                {/*                                                  !!( */}
                                                {/*                                                      selectedSlot?.slot.start === */}
                                                {/*                                                      availableSlot?.slot.start */}
                                                {/*                                                  ) */}
                                                {/*                                              } */}
                                                {/*                                          /> */}
                                                {/*                                      ); */}
                                                {/*                                  } */}
                                                {/*                              ) */}
                                                {/*                              : "Geen slots gevonden"} */}
                                                {/*                          /!*<TimeSlot */}
                                                {/*  date={availableSlots[0]} */}
                                                {/*  onClick={() => setSelectedSlot(new Date())} */}
                                                {/*  selected={true} */}
                                                {/* /> */}
                                                {/*    *!/ */}
                                                {/*                      </div> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="flex justify-end">
                                    <ButtonRound>
                                        Verstuur uitnodigingen
                                    </ButtonRound>
                                </div>
                            </div>
                        )}
                    </form>
                </Section>
            </div>
    );
}
export default EditMeeting;
