import { useEffect, useState } from "react";
import { AxiosError } from "axios";
import { useMsal } from "@azure/msal-react";
import { getProfilePictureByEmail } from "../../services/GraphService";
import { loginRequest } from "../../services/msal";

const useProfilePicture = (email: string) => {
    const { instance } = useMsal();
    const [picture, setPicture] = useState<string>("");
    const enrichUserWithPicture = () => {
        getProfilePictureByEmail(email)
            .then((res) => {
                const url = window.URL || window.webkitURL;
                const blobUrl = url.createObjectURL(res);
                setPicture(blobUrl);
            })
            .catch((err: AxiosError) => {
                if (err.response?.status === 401) {
                    instance.loginRedirect(loginRequest);
                }
                setPicture("");
            });
    };

    useEffect(() => {
        enrichUserWithPicture();
    }, [email]);

    return {
        picture
    };
};

export default useProfilePicture;
