import React, { FC } from "react";
import { useUser } from "../../contexts";

type ButtonSignInProps = {
    children: React.ReactNode;
};
export const ButtonSignIn: FC<ButtonSignInProps> = ({ children }) => {
    const { login } = useUser();
    return <div onClick={login}>{children}</div>;
};
export default ButtonSignIn;
