import { FC, SVGProps } from "react";

interface IconSpeakingProps extends SVGProps<SVGSVGElement> {}

const IconSpeaking: FC<IconSpeakingProps> = (props) => (
    <svg
        width={25}
        height={21}
        viewBox="0 0 25 21"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
        fillOpacity={0.1}
        {...props}
    >
        <path d="M15.3724 13.1783C13.497 12.5862 14.0309 12.1149 15.8207 11.4425C16.6781 11.1198 16.1567 10.4394 16.1801 9.89498C16.1906 9.6458 17.1704 9.69547 17.0993 8.73432C17.0492 8.05747 15.5582 7.09308 15.1519 6.68155C14.9173 6.44409 15.6301 5.7943 15.1281 5.22285C14.4428 4.44181 14.3346 3.08286 13.9198 2.32282C13.9198 2.32282 14.236 1.83981 13.9937 1.5652C11.9034 -0.800951 4.10305 -0.583681 2.10966 2.8987C-0.128067 6.80796 -0.157952 12.211 4.49479 15.122C6.56411 16.4163 3.91365 21 3.91365 21H12.1194C12.1194 20.2173 11.2043 17.4098 12.806 17.5358C14.1976 17.6452 15.9019 17.5851 15.7508 16.0133C15.7052 15.5392 15.6515 15.1123 15.9827 14.7193C16.3138 14.3268 16.7932 13.6265 15.3724 13.1783Z" />
        <path d="M17.2507 12.4403L24.8718 13.4386V11.4424L17.2507 12.4403Z" />
        <path d="M23.4421 19.0136L24.2595 17.2847L17.2507 13.5015L23.4421 19.0136Z" />
        <path d="M24.2595 7.57864L23.4421 5.84937L17.2507 11.3623L24.2595 7.57864Z" />
    </svg>
);

export default IconSpeaking;
