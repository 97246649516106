import { useCallback, useState } from "react";
import axios from "axios";
import { MeetingOld, Person } from "../../interfaces";

export const useMeeting = () => {
    const [meeting] = useState<MeetingOld>();
    const [meetings] = useState<MeetingOld[]>([]);
    const [invitations, setInvitations] = useState<Person[]>([]);

    const [meetingsByUser, setMeetingsByUser] = useState<MeetingOld[]>([]);

    const [newMeeting, setNewMeeting] = useState<MeetingOld[]>([
        {
            location: "0",
            date: {
                date: new Date(),
                slot: {
                    start: new Date(),
                    end: new Date()
                }
            },
            people: {
                hr: [""]
            }
        }
    ]);

    const [selectedDate, setSelectedDate] = useState<Date>(new Date());

    const allMeetings = useCallback(async () => {
        // await getData("/meetings", true).then((data) => {
        //     setMeetings(data);
        // });
    }, []);

    const getMeeting = useCallback(async () => {
        // setMeeting(data);
        // return data;
    }, []);

    const getMeetingsByUser = useCallback(async () => {
        // await getData("/meetings", true).then((data) => {
        //     setMeetingsByUser(data);
        // });
        // TODO: change meetings to user meetings
        // await getData("/meetings/user/" + email, true).then((data) => {
        //     setMeetingsByUser(data);
        // });
        // setMeetingsByUser(data);
        // return data;
    }, []);

    const createMeeting = () => {
        // axios.post("/meetings", meetings).then((data)=>{
        //     mutate("/meetings");
        //     return data;
        // });
    };

    const updateMeeting = useCallback(
        async (meetingId: number, m: MeetingOld) => {
            await axios.put("/meetings", m);
        },
        []
    );

    const sendInvitations = useCallback(
        (type: string) => {
            const meetingsToCreate: any[] = [];
            invitations.forEach(async () => {
                type === "recap" &&
                    (await axios.post(
                        "/meeting/meetingwithouttimeslot?type=1",
                        ""
                    ));
                type === "loon" &&
                    (await axios.post(
                        "/meeting/meetingwithouttimeslot?type=2",
                        ""
                    ));
                // meetingsToCreate.push({
                //     type: "recap",
                //     email: "mamama@gmail.com",
                //     attendees: ["amma@zzFz.Zef", "kjlmzfj@zeza.efz"],
                // });

                type === "both" && axios.post("/meetings", meetingsToCreate);
                // meetingsToCreate.push({
                //     type: "loon",
                //     email: "mamama@gmail.com",
                //     attendees: ["amma@zzFz.Zef", "kjlmzfj@zeza.efz"],
                // });
            });

            // await postData("/meetings", meetingsToCreate);
            // await postData("/meetings", meetingsToCreate);
        },
        [invitations]
    );

    const addUsersToInvitations = (users: Person[]) => {
        setInvitations(users);
    };
    const removeUsersToInvitations = () => {
        setInvitations([]);
    };

    return {
        selectedDate,
        setSelectedDate,
        setMeetingsByUser,
        meetingsByUser,
        getMeetingsByUser,
        newMeeting,
        setNewMeeting,
        addUsersToInvitations,
        removeUsersToInvitations,
        invitations,
        setInvitations,
        sendInvitations,
        meeting,
        meetings,
        allMeetings,
        createMeeting,
        updateMeeting,
        getMeeting
    };
};
