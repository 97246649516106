import { useQuery } from "@tanstack/react-query";
import React, { FC, useEffect, useState } from "react";
import { Option } from "react-tailwindcss-select/dist/components/type";
import { User } from "../../interfaces";
import {
    getAllPartners,
    getUserWithPartners
} from "../../services/UserService";
import { PartnerSelect } from "../select/PartnerSelect";

interface UpdateRecapPartnersProps {
    user: User;
    recapPartners: User[];
    salaryPartners: User[];
    setRecapPartners: (partners: User[]) => void;
    setSalaryPartners: (partners: User[]) => void;
}

export const UpdatePartnersForm: FC<UpdateRecapPartnersProps> = ({
    user,
    recapPartners,
    salaryPartners,
    setRecapPartners,
    setSalaryPartners
}) => {
    const { data: userWithPartners } = useQuery(
        ["userWithPartners", user?.email],
        () => getUserWithPartners(user?.email)
    );
    const { data: partners } = useQuery(["partners"], getAllPartners);
    const [recapValues, setRecapValues] = useState<Option | Option[]>([]);
    const [salaryValues, setSalaryValues] = useState<Option | Option[]>([]);

    const handleClickAddRecapPartner = () => {
        if (Array.isArray(recapValues)) {
            const partnersToAdd: User[] = recapValues.map((o) =>
                JSON.parse(o.value)
            );
            setRecapPartners([...recapPartners, ...partnersToAdd]);
            setRecapValues([]);
        }
    };

    const handleClickAddSalaryPartner = () => {
        if (Array.isArray(salaryValues)) {
            const partnersToAdd: User[] = salaryValues.map((o) =>
                JSON.parse(o.value)
            );
            setSalaryPartners([...salaryPartners, ...partnersToAdd]);
            setSalaryValues([]);
        }
    };

    useEffect(() => {
        if (userWithPartners?.recapMeetingPartners)
            setRecapPartners(
                userWithPartners.recapMeetingPartners.map(
                    (partner) => partner.user
                )
            );
        if (userWithPartners?.salaryMeetingPartners)
            setSalaryPartners(
                userWithPartners.salaryMeetingPartners.map(
                    (partner) => partner.user
                )
            );
    }, [setRecapPartners, setSalaryPartners, userWithPartners]);

    const getRecapOptions = () =>
        partners
            ? partners
                  .filter(
                      (p) => !recapPartners.some((r) => p.email === r.email)
                  )
                  .map((p) => ({
                      value: JSON.stringify(p),
                      label: p.fullname
                  }))
            : [];
    const getSalaryOptions = () =>
        partners
            ? partners
                  .filter(
                      (p) => !salaryPartners.some((r) => p.email === r.email)
                  )
                  .map((p) => ({
                      value: JSON.stringify(p),
                      label: p.fullname
                  }))
            : [];

    return (
        <div className="text-left mt-6">
            <PartnerSelect
                title="Recap partners"
                options={getRecapOptions()}
                data={recapPartners}
                setData={setRecapPartners}
                handleClick={handleClickAddRecapPartner}
                values={recapValues}
                setValues={setRecapValues}
            />
            <PartnerSelect
                title="Salary partners"
                options={getSalaryOptions()}
                data={salaryPartners}
                setData={setSalaryPartners}
                handleClick={handleClickAddSalaryPartner}
                values={salaryValues}
                setValues={setSalaryValues}
            />
        </div>
    );
};
export default UpdatePartnersForm;
