import { useQuery } from "@tanstack/react-query";
import React, { ChangeEvent, FC, useState } from "react";
import { getUsers } from "../../services/UserService";
import { Section } from "../ui";
import { User } from "../../interfaces";
import { IconCancel, IconCheckCircle, IconEdit, IconDelete } from "../icons";
import { Pagination } from "../pagination";
import { LoadingRows } from "../loadingRows";

interface UsersTableProps {
    setShowDeleteModal: (show: boolean) => void;
    setShowUpdateModal: (show: boolean) => void;
    setUserEmail: (email: string) => void;
    setUserToUpdate: (user: User) => void;
}
export const UsersTable: FC<UsersTableProps> = ({
    setShowDeleteModal,
    setShowUpdateModal,
    setUserEmail,
    setUserToUpdate
}) => {
    const { data, isLoading, isError } = useQuery(["users"], getUsers);
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(0);
    const pageSize = 4;
    const totalPages = data ? data.length / pageSize : 0;

    const filteredData: User[] | undefined = data
        ?.filter(
            (item: User) =>
                item.fullname.includes(search) || item.email.includes(search)
        )
        .slice(page * pageSize, page * pageSize + pageSize);

    const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
        setPage(0);
    };

    return (
        <div>
            <Section>
                {!isError && !isLoading && (
                    <div className="flex items-center slide ">
                        <div className="flex">
                            <input
                                onChange={handleSearch}
                                type="text"
                                className=" block w-full px-4 py-2 rounded-md text-orange-500 bg-white border focus:border-orange-400 focus:ring-orange-300 focus:outline-none focus:ring focus:ring-opacity-40"
                                placeholder="Zoek op naam of email..."
                            />
                        </div>
                    </div>
                )}

                <div className="items-center justify-center ">
                    <table className="min-w-max w-full table-auto">
                        <thead>
                            <tr className=" bg-black/5 text-gray-600 uppercase text-sm leading-normal">
                                <th className="py-3 px-6 text-left">Naam</th>
                                <th className="py-3 px-6 text-left">
                                    Email adres
                                </th>
                                <th className="py-3 px-6 text-center">
                                    Meeting
                                </th>
                                <th className="py-3 px-6 text-center">Admin</th>
                                <th className="py-3 px-6 text-center">
                                    Acties
                                </th>
                            </tr>
                        </thead>
                        {isLoading && <LoadingRows size={pageSize} />}
                        {!isLoading && (
                            <tbody>
                                {filteredData?.map((user) => (
                                    <tr
                                        className="border-b border-gray-200 hover:bg-gray-100"
                                        key={user.email}
                                    >
                                        <td className="py-3 px-6 text-left">
                                            <span>{user.fullname}</span>
                                        </td>
                                        <td className="py-3 px-6 text-left">
                                            <span>{user.email}</span>
                                        </td>
                                        <td>
                                            <div className="flex justify-center">
                                                {!user.hasPlannedMeeting ? (
                                                    <IconCancel />
                                                ) : (
                                                    <IconCheckCircle />
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            <div className="flex justify-center">
                                                {!user.isAdmin ? (
                                                    <IconCancel />
                                                ) : (
                                                    <IconCheckCircle />
                                                )}
                                            </div>
                                        </td>

                                        <td>
                                            <div className="flex justify-center">
                                                <button
                                                    onClick={() => {
                                                        setShowUpdateModal(
                                                            true
                                                        );
                                                        setUserToUpdate(user);
                                                    }}
                                                >
                                                    <IconEdit className="self-center w-4  m-0.5 transform hover:fill-orange-500 hover:scale-110" />
                                                </button>
                                                <button
                                                    onClick={() => {
                                                        setUserEmail(
                                                            user.email
                                                        );
                                                        setShowDeleteModal(
                                                            true
                                                        );
                                                    }}
                                                >
                                                    <IconDelete className="self-center w-4 m-0.5 transform hover:fill-orange-500 hover:scale-110" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        )}
                    </table>
                    {isError && (
                        <div className="text-center mt-6 font-medium">
                            <span>Geen gebruikers gevonden.</span>
                        </div>
                    )}
                </div>
                {!isError && !isLoading && (
                    <Pagination
                        totalPages={totalPages}
                        setPage={setPage}
                        page={page}
                    />
                )}
            </Section>
        </div>
    );
};
export default UsersTable;
