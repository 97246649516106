import React, { FC } from "react";
import { useUser } from "../../contexts";
import { Privileges } from "../../pages";

interface RoleGuardProps {
    privilege: "Consultant" | "Partner" | "Admin";
    children: React.ReactElement;
}

const RoleGuard: FC<RoleGuardProps> = ({ privilege, children }) => {
    const { user } = useUser();

    if (user?.role !== "Admin" && user?.role !== privilege) {
        return <Privileges />;
    }

    return children;
};

export default RoleGuard;
