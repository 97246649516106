import React, { FC } from "react";

interface ButtonPreviousPageProps {
    pageNumber: number;
    onClick: () => void;
    className: string;
}

export const ButtonPreviousPage: FC<ButtonPreviousPageProps> = ({
    pageNumber,
    onClick,
    className
}) => (
    <button className={className} onClick={onClick}>
        {pageNumber}
    </button>
);

export default ButtonPreviousPage;
