import React, { FC, SVGProps } from "react";

interface IconEditProps extends SVGProps<SVGSVGElement> {
}

const IconAvailable: FC<IconEditProps> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="21" viewBox="0 0 18 21" fill="none" {...props}>
        <path
              d="M13 10.03a.754.754 0 0 0-1.06 0l-4.35 4.35L6 12.79a.754.754 0 0 0-1.06 0c-.29.29-.29.77 0 1.06l1.94 1.94c.39.39 1.02.39 1.41 0l4.7-4.7c.3-.29.3-.77.01-1.06Zm3-7.53h-1v-1c0-.55-.45-1-1-1s-1 .45-1 1v1H5v-1c0-.55-.45-1-1-1s-1 .45-1 1v1H2c-1.11 0-1.99.9-1.99 2L0 18.5a2 2 0 0 0 2 2h14c1.1 0 2-.9 2-2v-14c0-1.1-.9-2-2-2Zm-1 16H3c-.55 0-1-.45-1-1v-10h14v10c0 .55-.45 1-1 1Z" />
    </svg>
);

export default IconAvailable;
