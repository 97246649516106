import axios from "axios";
import { requestToken } from "./msal";
import { TimeSlot, TimeSlotPost } from "../interfaces";

export const getTimeSlots = async (id: number) => {
    const token = await requestToken();
    const response = await axios.get<TimeSlot[]>(`/timeslot/getTimeslots`, {
        params: {
            partnerId: id
        },
        headers: {
            Authorization: `Bearer ${token.accessToken}`
        }
    });

    return response.data;
};

export const createTimeSlot = async (timeSlot: TimeSlotPost) => {
    const token = await requestToken();

    const response = await axios.post(`/timeslot/createtimeslot`, {}, {
            params: {
                ...timeSlot
            },
            headers: {
                Authorization: `Bearer ${token.accessToken}`
            }
        }
    );

    return response.data;
};

export const updateTimeSlot = async (timeSlot: TimeSlot) => {
    const token = await requestToken();

    const response = await axios.put(`/timeslot/updatetimeslot`, timeSlot, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`
        }
    });

    return response.data;
};

export const deleteTimeSlot = async (timeslotId: number) => {
    const token = await requestToken();

    const response = await axios.delete(`/timeslot/deletetimeslot`, {
        headers: {
            Authorization: `Bearer ${token.accessToken}`
        },
        params: {
            timeslotId
        }
    });

    return response.data;
};
