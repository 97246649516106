import { useIsAuthenticated } from "@azure/msal-react";
import { IconAvailable, IconHome, IconLogout, IconPeople } from "../icons";
import { ButtonSignIn, ButtonSignOut } from "../button";
import { MenuItem } from ".";
import { useUser } from "../../contexts";

const ConsultantItems = () => [
    <MenuItem
        key="home"
        link="/"
        icon={<IconHome />}
    >
        Overzicht
    </MenuItem>
];

const PartnerItems = () => [
    <MenuItem
        key="timeslots"
        link="/timeslots"
        icon={<IconAvailable className="fill-black" />}
    >
        Beschikbaarheden
    </MenuItem>
];

const AdminItems = () => [
    <MenuItem
        key="users"
        link="/admin"
        icon={<IconPeople />}
    >
        Gebruikers
    </MenuItem>
];

export function Menu() {
    const isAuthenticated = useIsAuthenticated();
    const { user } = useUser();

    return (
        <div className="py-5 md:py-3 flex flex-col md:flex-row gap-4 md:justify-between container">
            {isAuthenticated && (
                <ul className="grid md:grid-flow-col gap-4 md:gap-8 justify-start items-center ">
                    {ConsultantItems()}
                    {user?.role === "Partner" && PartnerItems()}
                    {user?.role === "Admin" && AdminItems()}
                    <ButtonSignOut>
                        <MenuItem icon={<IconLogout />}>Uitloggen</MenuItem>
                    </ButtonSignOut>
                </ul>
            )}
            {!isAuthenticated && (
                <ButtonSignIn>
                    <MenuItem icon={<IconLogout />}>Inloggen</MenuItem>
                </ButtonSignIn>
            )}
        </div>
    );
}

export default Menu;
