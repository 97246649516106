import { FC, SVGProps } from "react";

interface IconBackProps extends SVGProps<SVGSVGElement> {}

const IconBack: FC<IconBackProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill="none"
        viewBox="0 0 22 22"
        {...props}
    >
        <path d="M13.466 15.85a.913.913 0 0 0 0-1.293L9.909 11l3.557-3.557a.913.913 0 1 0-1.293-1.292l-4.207 4.207a.913.913 0 0 0 0 1.293l4.207 4.207a.921.921 0 0 0 1.293-.009Z" />
    </svg>
);
export default IconBack;
