import React, { FC, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { UsersTable } from "../table";
import { DeleteUserModal, UpdateUserModal } from "../modals";
import { UpdateUser, User } from "../../interfaces";
import { deleteUser, updateUser } from "../../services/UserService";
import { useToast } from "../../shared/hooks/useToast";

export const UserManagement: FC = () => {
    const client = useQueryClient();

    const [userEmail, setUserEmail] = useState("");
    const deleteMutation = useMutation((mail: string) => deleteUser(mail), {
        onSuccess: () => client.refetchQueries(["users"])
    });
    const updateMutation = useMutation((user: UpdateUser) => updateUser(user), {
        onSuccess: () => client.refetchQueries(["users"])
    });
    useToast(
        deleteMutation.isLoading,
        deleteMutation.isSuccess,
        deleteMutation.isError,
        "Gebruiker verwijderd",
        "Er is iets misgegaan"
    );
    useToast(
        updateMutation.isLoading,
        updateMutation.isSuccess,
        updateMutation.isError,
        "Gebruiker gewijzigd",
        "Er is iets misgegaan"
    );
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUpdateModal, setShowUpdateModal] = useState(false);
    const handleDeleteUser = () => {
        setShowDeleteModal(false);
        deleteMutation.mutate(userEmail);
    };
    const [userToUpdate, setUserToUpdate] = useState<User | null>(null);

    const handleUpdateUser = (user: UpdateUser | null) => {
        if (user) {
            updateMutation.mutate(user);
        }
        setShowUpdateModal(false);
    };

    return (
        <div>
            {showDeleteModal && (
                <DeleteUserModal
                    handleDeleteUser={handleDeleteUser}
                    onClose={() => setShowDeleteModal(false)}
                />
            )}
            {showUpdateModal && (
                <UpdateUserModal
                    handleUpdateUser={handleUpdateUser}
                    onClose={() => setShowUpdateModal(false)}
                    user={userToUpdate}
                />
            )}
            <UsersTable
                setUserEmail={setUserEmail}
                setUserToUpdate={setUserToUpdate}
                setShowUpdateModal={setShowUpdateModal}
                setShowDeleteModal={setShowDeleteModal}
            />
        </div>
    );
};
export default UserManagement;
