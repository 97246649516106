import { Route, Routes } from "react-router-dom";
import { PrivateRoute } from "../../components/routes";
import { AddMeeting, AdminPanel, EditMeeting, Home } from "../../pages";
import { Timeslots } from "../../pages/Timeslots";
import RoleGuard from "../../components/routes/RoleGuard";

export const PLATORouter = () => (
    <Routes>
        <Route
            path="/"
            element={
                <PrivateRoute>
                    <Home />
                </PrivateRoute>
            }
        />
        <Route
            path="/meeting/new"
            element={
                <PrivateRoute>
                    <AddMeeting />
                </PrivateRoute>
            }
        />
        <Route
            path="/meeting/:id"
            element={
                <PrivateRoute>
                    <EditMeeting />
                </PrivateRoute>
            }
        />
        <Route
            path="/admin"
            element={
                <PrivateRoute>
                    <RoleGuard privilege="Admin">
                        <AdminPanel />
                    </RoleGuard>
                </PrivateRoute>
            }
        />
        <Route
            path="/timeslots"
            element={
                <PrivateRoute>
                    <RoleGuard privilege="Partner">
                        <Timeslots />
                    </RoleGuard>
                </PrivateRoute>
            }
        />
        <Route
            path={"/*"}
            element={
                <PrivateRoute>
                    <Home />
                </PrivateRoute>
            }
        />
    </Routes>
);

export default PLATORouter;
