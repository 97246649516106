import { motion } from "framer-motion";
import React, { FC } from "react";

interface ButtonAnimationProps {
    children: React.ReactNode;
}

export const ButtonAnimation: FC<ButtonAnimationProps> = ({ children }) => (
    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.97 }} className="flex-grow">
        {children}
    </motion.div>
);
export default ButtonAnimation;
