import React, { FC, useState } from "react";
import { User, UpdateUser } from "../../interfaces";
import { UpdatePartnersForm } from "../form";
import { IconEdit } from "../icons";
import { Modal } from ".";

interface UpdateUserModalProps {
    handleUpdateUser: (user: UpdateUser) => void;
    onClose: () => void;
    user: User | null;
}

export const UpdateUserModal: FC<UpdateUserModalProps> = ({
    handleUpdateUser,
    onClose,
    user
}) => {
    const [recapPartners, setRecapPartners] = useState<User[]>([]);
    const [salaryPartners, setSalaryPartners] = useState<User[]>([]);

    return (
        <Modal>
            <div className="rounded-md p-6">
                <div className="flex">
                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-orange-100 sm:mx-0 sm:h-10 sm:w-10">
                        <IconEdit className="fill-orange-500" />
                    </div>
                    <div className="text-center ml-4 sm:text-left">
                        <h3 className="text-gray-900 !m-0">{user?.fullname}</h3>
                        <h4 className="font-medium text-gray-900">
                            Gesprekspartners wijzigen
                        </h4>
                    </div>
                </div>
                <div>
                    {user && (
                        <UpdatePartnersForm
                            user={user}
                            recapPartners={recapPartners}
                            salaryPartners={salaryPartners}
                            setSalaryPartners={setSalaryPartners}
                            setRecapPartners={setRecapPartners}
                        />
                    )}
                </div>
            </div>
            <div className="bg-gray-50 sm:flex sm:flex-row-reverse p-6 rounded-b-md">
                <button
                    type="button"
                    onClick={() => {
                        if (user) {
                            handleUpdateUser({
                                ...user,
                                recapMeetingPartners: recapPartners.map(
                                    (u) => u.id
                                ),
                                salaryMeetingPartners: salaryPartners.map(
                                    (u) => u.id
                                )
                            });
                        }
                    }}
                    className="w-full justify-center rounded-md border border-transparent bg-orange-500 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-orange-500 focus:outline-none focus:ring-2 focus:ring-orange-400 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Opslaan
                </button>
                <button
                    type="button"
                    onClick={onClose}
                    className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                >
                    Sluiten
                </button>
            </div>
        </Modal>
    );
};
export default UpdateUserModal;
