import { SVGProps } from "react";

interface IconCancelProps extends SVGProps<SVGSVGElement> {}

const IconCancel = (props: IconCancelProps) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        xmlns="http://www.w3.org/2000/svg"
        fill="#B9B4B4FF"
        {...props}
    >
        <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm4.561 10.915-1.647 1.647L8 9.647 5.085 12.56l-1.647-1.647L6.354 8 3.44 5.085l1.647-1.646L8 6.353l2.915-2.915 1.647 1.647L9.646 8l2.915 2.915Z" />
    </svg>
);

export default IconCancel;
