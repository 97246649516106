import React from "react";

export const Footer = () => (
    <footer className=" bg-white text-sm mt-12">
        <div className="container  py-6 items-center ">
            <b>PCP</b> : Performance Cycle Planner
            <br />A web application made by Axxes for axxes during the 2022
            traineeship.
            <br />
            <span className="text-xs mt-2">
                Peter Junior, Ewoud DP, Glenn TQ, René VC, Maxime VM
            </span>
        </div>
    </footer>
);
export default Footer;
