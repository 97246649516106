import React, { FC } from "react";
import { ButtonAnimation } from "./ButtonAnimation";

interface ButtonSecondaryProps {
    children?: React.ReactNode;
    className?: string;
    onClick?: () => void;
}

export const ButtonSecondary: FC<ButtonSecondaryProps> = ({
    children,
    onClick,
    className = ""
}) => (
    <ButtonAnimation>
        <button type="button" className={`button-secondary ${className}`} onClick={onClick}>
            {children}
        </button>
    </ButtonAnimation>
);
export default ButtonSecondary;
