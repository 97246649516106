import Select from "react-tailwindcss-select";
import React, { FC } from "react";
import { Option } from "react-tailwindcss-select/dist/components/type";
import { IconAdd, IconCancel } from "../icons";
import { User } from "../../interfaces";

interface PartnerSelectProps {
    title: string;
    options: Option[];
    values: Option | Option[];
    setValues: (p: Option | Option[]) => void;
    data: User[];
    setData: (users: User[]) => void;
    handleClick: () => void;
}

export const PartnerSelect: FC<PartnerSelectProps> = ({
    title,
    options,
    values,
    data,
    setData,
    setValues,
    handleClick
}) => (
    <div className="mt-4">
        <label>{title}</label>
        <div className="mt-2 w-full flex">
            <Select
                searchInputPlaceholder="Zoek op naam..."
                isSearchable
                isMultiple
                options={options}
                value={values}
                isClearable
                onChange={(o) => setValues(o || [])}
            />
            <IconAdd
                className="self-center ml-4 transform scale-110 hover:scale-125 transition duration-200 cursor-pointer"
                onClick={handleClick}
            />
        </div>
        {data &&
            data.map((u) => (
                <div
                    className="flex mt-3 justify-between bg-orange-50 border-b-2 rounded-sm"
                    key={u.email}
                >
                    <span className="p-1.5">{u.fullname}</span>
                    <IconCancel
                        className="cursor-pointer self-center mr-2"
                        onClick={() => {
                            setData(
                                data.filter(
                                    (partner) => partner.email !== u.email
                                )
                            );
                        }}
                    />
                </div>
            ))}
    </div>
);
