import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import { Meeting } from "../../interfaces";
import { Card, CardAdd } from "../../components/card";

interface MeetingsListProps {
    meetings: Meeting[];
}

const MeetingsList: FC<MeetingsListProps> = ({ meetings }) => {
    const navigate = useNavigate();
    return (
        <div className="card-list">
            <CardAdd />
            {meetings.map((meeting) => (
                <Card
                    key={meeting.id}
                    onClickView={() => navigate(`/meeting/${meeting.id}`)}
                    attendees={meeting.partners}
                    type={meeting.type}
                    timeslot={{
                        id: -1,
                        start: meeting.start,
                        end: meeting.end,
                        location: meeting.location
                    }}
                    hoverable={false}
                />
            ))}
        </div>
    );
};

export default MeetingsList;
