import { FC, SVGProps } from "react";

interface IconDatePickerProps extends SVGProps<SVGSVGElement> {}

const IconDatePicker: FC<IconDatePickerProps> = (props) => (
    <svg
        width={16}
        height={16}
        viewBox="0 0 16 16"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path d="M6 7.3335H4.66667V8.66683H6V7.3335ZM8.66667 7.3335H7.33333V8.66683H8.66667V7.3335ZM11.3333 7.3335H10V8.66683H11.3333V7.3335ZM12.6667 2.66683H12V1.3335H10.6667V2.66683H5.33333V1.3335H4V2.66683H3.33333C2.59333 2.66683 2.00667 3.26683 2.00667 4.00016L2 13.3335C2 14.0668 2.59333 14.6668 3.33333 14.6668H12.6667C13.4 14.6668 14 14.0668 14 13.3335V4.00016C14 3.26683 13.4 2.66683 12.6667 2.66683ZM12.6667 13.3335H3.33333V6.00016H12.6667V13.3335Z" />
    </svg>
);

export default IconDatePicker;
