import React from "react";

const LoadingCard = () => (
    <div className="card min-h-[250px]">
        <div className="h-4 bg-gray-200 mt-3 rounded" />
        <div className="h-4 bg-gray-200 mt-3 rounded" />
        <div className="h-4 bg-gray-200 mt-3 rounded" />
        <div className="h-4 bg-gray-200 mt-3 rounded" />
    </div>
);

export default LoadingCard;
