import { FC } from "react";
import { User } from "../../interfaces";
import useProfilePicture from "../../shared/hooks/useProfilePicture";
import { IconPeople } from "../icons";

interface AvatarProps {
    person: User;
    className?: string;
    position?: string;
    showFunction?: boolean;
    loading?: boolean;
}

export const Avatar: FC<AvatarProps> = ({
    person,
    className,
    position = "left",
    showFunction = true
}) => {
    const { picture } = useProfilePicture(person.email);
    return (
        <div className={`flex gap-3 items-center ${className}`}>
            {picture && (
                <img
                    className="avatar scale-105"
                    src={picture}
                    alt={person.fullname}
                />
            )}
            {!picture && <IconPeople />}
            <div
                className={`text-sm ${
                    position === "right" ? "text-right" : ""
                }`}
            >
                <p className="font-semibold">
                    {person.fullname || "Geen naam gevonden"}
                </p>
                {showFunction && person.role && (
                    <p>{person.role || "Geen functie gevonden"}</p>
                )}
            </div>
        </div>
    );
};
export default Avatar;
