import React, { FC, SVGProps } from "react";

interface IconCancelProps extends SVGProps<SVGSVGElement> {
}

const IconClose: FC<IconCancelProps> = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="17" fill="#B9B4B4FF"
         viewBox="0 0 15 17" {...props}>
        <path
            d="m7.5 7.155 6.22-6.91a.698.698 0 0 1 1.06 0 .9.9 0 0 1 0 1.178l-6.22 6.91 6.22 6.911a.9.9 0 0 1 0 1.179.698.698 0 0 1-1.06 0L7.5 9.512l-6.22 6.91a.698.698 0 0 1-1.06 0 .9.9 0 0 1 0-1.178l6.22-6.91L.22 1.422a.9.9 0 0 1 0-1.179.698.698 0 0 1 1.06 0l6.22 6.91Z" />
    </svg>
);

export default IconClose;
