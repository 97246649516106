import { useMemo, useState } from "react";
import Calendar from "react-calendar";
import moment from "moment";
import { useMutation, useQuery } from "@tanstack/react-query";
import { TimeSlot } from "../components/timeslot";
import { Section } from "../components/ui";
import { Card } from "../components/card";
import { ButtonRound } from "../components/button";
import {
    addMeeting,
    getAvailableMeetingTimes
} from "../services/MeetingService";
import { AddMeetingType, TimeRange, TimeSlot as timeSlot } from "../interfaces";
import { getUserWithPartners } from "../services/UserService";
import { useUser } from "../contexts";
import { useToast } from "../shared/hooks/useToast";

function AddMeeting() {
    const { user } = useUser();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const [selectedSlot, setSelectedSlot] = useState<timeSlot | null>(null);
    const { mutate, isError, isLoading, isSuccess } = useMutation(
        (meetingType: AddMeetingType) => addMeeting(meetingType)
    );
    const { data: userWithPartners } = useQuery(
        ["userWithPartners", user?.email],
        () => getUserWithPartners(user ? user.email : ""),
        { enabled: user !== null }
    );
    useToast(
        isLoading,
        isSuccess,
        isError,
        "Meeting toegevoegd",
        "Er ging iets mis"
    );
    const { data } = useQuery(["AvailableMeetingTimes", selectedDate], () =>
        getAvailableMeetingTimes(selectedDate)
    );
    const handleAddMeeting = () => {
        if (selectedSlot) {
            mutate({
                startDateTime: selectedSlot.start,
                locationString: selectedSlot.location,
                userId: user ? user.id : -1
            });
        }
    };

    const selectDate = (d: Date) => {
        const newDate = new Date(d);
        newDate.setHours(1, 0);
        setSelectedDate(newDate);
    };

    const timeSlotsOfToday = useMemo(
        () =>
            data?.filter(
                (d) => new Date(d.begin).getDate() === selectedDate.getDate()
            ) ?? [],
        [data, selectedDate]
    );

    return (
            <div className="container">
                <Section>
                    <h1>Plan meetings</h1>

                    <div className="grid gap-4">
                        <div className="grid gap-4 md:grid-cols-2 items-start mt-4">
                            <div>
                                <h3>Kies datum</h3>
                                <Calendar
                                    onChange={selectDate}
                                    className="card mt-4"
                                    value={selectedDate}
                                    tileClassName={({ date, view }) =>
                                        view === "month" &&
                                        (date.getDay() === 6 ||
                                            date.getDay() === 0)
                                            ? "bg-red-100 text-black/40 cursor-not-allowed"
                                            : date.getDate() ===
                                              new Date().getDate()
                                            ? "bg-black/10"
                                            : "text-black/20 "
                                    }
                                    tileDisabled={({ date, view }) =>
                                        view === "month" &&
                                        (date.getDay() === 6 ||
                                            date.getDay() === 0)
                                    }
                                    next2Label={null}
                                    prev2Label={null}
                                />
                            </div>
                            <div>
                                <div className="grid gap-4">
                                    <div className="grid gap-2 mt-4">
                                        <h4 className="h4 ">
                                            <span>
                                                Beschikbare slots{" "}
                                                <span className="ml-1 text-xs font-normal">
                                                    voor{" "}
                                                    {moment(
                                                        selectedDate
                                                    ).format("dddd LL")}
                                                </span>
                                            </span>
                                        </h4>
                                        <div className="grid gap-2">
                                            {timeSlotsOfToday.length > 0
                                                ? timeSlotsOfToday.map(
                                                      (
                                                          availableSlot: TimeRange,
                                                          index
                                                      ) => (
                                                          <TimeSlot
                                                              key={index}
                                                              date={
                                                                  availableSlot
                                                              }
                                                              onClick={() => {
                                                                  if (
                                                                      selectedSlot?.start ===
                                                                      availableSlot?.begin
                                                                  ) {
                                                                      setSelectedSlot(
                                                                          null
                                                                      );
                                                                  } else {
                                                                      setSelectedSlot(
                                                                          {
                                                                              id: -1,
                                                                              start: availableSlot.begin,
                                                                              end: availableSlot.end,
                                                                              location:
                                                                                  availableSlot.location
                                                                          }
                                                                      );
                                                                  }
                                                              }}
                                                              selected={
                                                                  selectedSlot?.start ===
                                                                  availableSlot?.begin
                                                              }
                                                          />
                                                      )
                                                  )
                                                : "Geen slots gevonden"}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="grid gap-4 md:grid-flow-col">
                            <Card
                                attendees={userWithPartners?.recapMeetingPartners?.map(
                                    (p) => p.user
                                )}
                                type="RECAP"
                                clickable={false}
                                hoverable={false}
                                timeslot={
                                    selectedSlot
                                        ? {
                                              ...selectedSlot!,
                                              end: moment(selectedSlot?.start)
                                                  .add({
                                                      hour: 1,
                                                      minute: 30
                                                  })
                                                  .toDate()
                                          }
                                        : selectedSlot
                                }
                            />
                            <Card
                                attendees={userWithPartners?.salaryMeetingPartners?.map(
                                    (p) => p.user
                                )}
                                type="SALARY"
                                clickable={false}
                                hoverable={false}
                                timeslot={
                                    selectedSlot
                                        ? {
                                              ...selectedSlot,
                                              start: moment(selectedSlot?.end)
                                                  .subtract({
                                                      hour: 1
                                                  })
                                                  .toDate()
                                          }
                                        : selectedSlot
                                }
                            />
                        </div>

                        <div className="flex justify-end">
                            <ButtonRound onClick={handleAddMeeting}>
                                Plan meetings
                            </ButtonRound>
                        </div>
                    </div>
                </Section>
            </div>
    );
}

export default AddMeeting;
