import axios from "axios";
import { requestGraphToken } from "./msal";

const GRAPH_ENDPOINT = "https://graph.microsoft.com/v1.0";

export const getProfilePicture = async () => {
    const request = await requestGraphToken();
    const response = await axios.get(`${GRAPH_ENDPOINT}/me/photo/$value`, {
        responseType: "blob",
        headers: {
            Authorization: `Bearer ${request.accessToken}`
        }
    });
    return response.data;
};
export const getProfilePictureByEmail = async (email: string) => {
    const request = await requestGraphToken();
    const response = await axios.get(
        `${GRAPH_ENDPOINT}/users/${email}/photo/$value`,
        {
            responseType: "blob",
            headers: {
                Authorization: `Bearer ${request.accessToken}`
            }
        }
    );
    return response.data;
};
export default { getProfilePicture };
