import React, { FC } from "react";

interface ButtonNextPageProps {
    pageNumber: number;
    onClick: () => void;
    className: string;
}

export const ButtonNextPage: FC<ButtonNextPageProps> = ({
    pageNumber,
    onClick,
    className
}) => (
    <button className={className} onClick={onClick}>
        {pageNumber}
    </button>
);

export default ButtonNextPage;
