import { useQuery } from "@tanstack/react-query";
import {
    getMeetingsAsPartner,
    getMeetingsAsSubject
} from "../../services/MeetingService";
import { User } from "../../interfaces";

const useMeetings = (user: User | null) => {
    const consultant = user?.role === "Consultant" ?? false;
    const partner = user?.role === "Partner" || user?.role === "Admin";
    const asSubject = useQuery(["meetings"], () => getMeetingsAsSubject(), {
        enabled: consultant
    });

    const asPartner = useQuery(["meetings"], () => getMeetingsAsPartner(), {
        enabled: partner
    });

    if (consultant) {
        return { data: asSubject };
    }
    return { data: asPartner };
};

export default useMeetings;
