import { motion } from "framer-motion";
import React, { FC } from "react";
import { Footer } from "../footer";
import { Header } from "../header";

interface MainPageProps {
    children: React.ReactNode;
}

export const MainPage: FC<MainPageProps> = ({ children }) => (
    <main className="grid grid-rows-[auto,1fr,auto] w-screen min-h-screen bg-black/5">
        <Header />
        <motion.div
            exit={{ opacity: 0, translateY: 0, translateX: 0 }}
            initial={{ opacity: 0, translateY: 50, translateX: 0 }}
            animate={{ opacity: 1, translateY: 0, translateX: 0 }}
            transition={{ type: "linear", default: { duration: 0.15 } }}
            whileInView={{ opacity: 1 }}
        >
            {children}
        </motion.div>
        <Footer />
    </main>
);
export default MainPage;
