import React, { FC } from "react";
import { ButtonAnimation } from "./ButtonAnimation";

interface ButtonRoundProps {
    children?: React.ReactNode;
    onClick?: () => void;
}

export const ButtonRound: FC<ButtonRoundProps> = ({ children, onClick }) => (
    <ButtonAnimation>
        <button onClick={onClick} className="button-round" type="button">
            {children}
        </button>
    </ButtonAnimation>
);
export default ButtonRound;
