import { motion } from "framer-motion";
import { FC, ReactNode } from "react";

interface FadeInProps {
    children?: ReactNode;
}
export const FadeIn: FC<FadeInProps> = ({ children }) => (
    <motion.main
        exit={{ opacity: 0 }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ type: "linear", default: { duration: 0.2 } }}
        whileInView={{ opacity: 1 }}
        viewport={{ once: true }}
    >
        {children}
    </motion.main>
);
export default FadeIn;
