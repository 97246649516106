import React, { FC, ReactNode } from "react";

interface ModalProps {
    children?: ReactNode;
}
export const Modal: FC<ModalProps> = ({ children }) => (
    <div>
        <div
            className="relative z-10 rounded-md"
            aria-labelledby="modal-title"
            role="dialog"
            aria-modal="true"
        >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <div className="fixed inset-0 z-10 ">
                <div className="flex min-h-full justify-center p-4 text-center sm:items-center sm:p-0">
                    <div className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                        {children}
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default Modal;
