import { FC, ReactNode, useState } from "react";

interface AlertProps {
    text?: string;
    children?: ReactNode;
    className?: string;
}

export const Alert: FC<AlertProps> = ({
    text = "Waarschuwing",
    children,
    className
}) => {
    const [show, setShow] = useState<boolean>(true);

    return (
        <div
            className={`alert-click ${className}`}
            onClick={() => setShow(!show)}
        >
            <span className="text-xs uppercase text-black/20">{text}</span>
            <div className="h-4 w-[1.5px] bg-black/5" />
            <span>{children}</span>
        </div>
    );
};
export default Alert;
