import { motion } from "framer-motion";
import { FC, ReactNode } from "react";

interface SlideFromTopProps {
    children?: ReactNode;
}
export const SlideFromTop: FC<SlideFromTopProps> = ({ children }) => (
    <motion.main
        exit={{ marginTop: -50 }}
        initial={{ marginTop: -50 }}
        animate={{ marginTop: 0 }}
        transition={{ type: "linear", default: { duration: 0.2 } }}
        whileInView={{ marginTop: 0 }}
        viewport={{ once: true }}
        key="form"
    >
        {children}
    </motion.main>
);

export default SlideFromTop;
