import React, { FC } from "react";
import { IconNext } from "../icons";

interface ButtonNextProps {
    disabled: boolean;
    onClick: () => void;
}

export const ButtonNext: FC<ButtonNextProps> = ({ disabled, onClick }) => (
    <button
        disabled={disabled}
        className="group flex ml-2 bg-transparent hover:bg-gray-400 text-gray-400 font-semibold hover:text-white py-2 px-4 border border-gray-400 hover:border-transparent rounded disabled:border-gray-300 disabled:hover:bg-transparent"
        onClick={onClick}
    >
        <IconNext className="group-hover:fill-white fill-gray-400 group-disabled:fill-gray-300 group-disabled:group-hover:fill-gray-300" />
    </button>
);
export default ButtonNext;
