import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import { BrowserRouter } from "react-router-dom";
import { PLATORouter } from "./shared/routers";
import { MainPage } from "./components/ui";

function App() {
    return (
        <BrowserRouter>
            <MainPage>
                <PLATORouter />
                <ToastContainer
                    autoClose={1000}
                    pauseOnFocusLoss={false}
                    position="bottom-left"
                />
            </MainPage>
        </BrowserRouter>
    );
}

export default App;
