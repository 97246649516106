import { Id, toast } from "react-toastify";
import { useEffect, useState } from "react";

export const useToast = (
    isLoading: boolean,
    isSuccess: boolean,
    isError: boolean,
    successMessage: string,
    errorMessage: string
) => {
    const [toastId, setToastId] = useState<Id | null>(null);

    useEffect(() => {
        if (isLoading) setToastId(toast.loading("Even wachten..."));
    }, [isLoading]);

    useEffect(() => {
        if (toastId && isSuccess) {
            toast.update(toastId, {
                render: successMessage,
                type: "success",
                isLoading: false,
                autoClose: 2000
            });
        }
    }, [isSuccess, successMessage, toastId]);

    useEffect(() => {
        if (toastId && isError) {
            toast.update(toastId, {
                render: errorMessage,
                type: "error",
                isLoading: false,
                autoClose: 2000
            });
        }
    }, [errorMessage, isError, toastId]);

    return {};
};
