import React, { FC } from "react";
import moment from "moment";
import { IconAvailable, IconClose, IconDelete, IconEdit, IconNext } from "../../components/icons";
import { ButtonSecondary } from "../../components/button";
import { TimeSlot, TimeSlotPost } from "../../interfaces";
import useTimeSlotForm from "./useTimeSlotForm";
import { useUser } from "../../contexts";
import { FadeIn, SlideFromTop } from "../../components/animation";

interface TimeSlotFormProps {
    close: () => void;
    submit: (timeslot: TimeSlotPost) => void;
    update: (timeslot: TimeSlot) => void;
    startDate: Date;
    endDate: Date;
    timeslot: TimeSlot | null;
    deleteSlot: (id: number) => void;
}

const TimeSlotForm: FC<TimeSlotFormProps> = ({
                                                 close,
                                                 submit,
                                                 update,
                                                 startDate,
                                                 endDate,
                                                 timeslot,
                                                 deleteSlot
                                             }) => {
    const { user } = useUser();
    const {
        start,
        end,
        location,
        changeDate,
        changeTime,
        setLocation,
        timeslot: editingSlot,
        showDelete,
        setShowDelete
    } = useTimeSlotForm(startDate, endDate, timeslot);
    const onSubmit = () => {
        if (editingSlot) {
            update({
                ...editingSlot,
                start,
                end,
                location
            });
        } else {
            submit({
                startDateTime: start,
                endDateTime: end,
                location,
                partnerId: user?.id ?? -1
            });
        }
    };

    return (
        <SlideFromTop>
            <form className="card min-w-[335px] h-fit cursor-auto ">
                {!showDelete && (
                    <FadeIn>
                        <div className="flex justify-between">
                            <div className="flex gap-2">
                                {editingSlot && (
                                    <IconEdit className="self-center fill-orange-500" />
                                )}
                                {!editingSlot && (
                                    <IconAvailable className="self-center fill-orange-500" />
                                )}
                                <h3 className="flex justify-between">
                                    {editingSlot
                                        ? "Tijdslot wijzigen"
                                        : "Tijdslot kiezen"}
                                </h3>
                            </div>

                            <IconClose
                                onClick={close}
                                className="cursor-pointer
      self-center transition duration-200 hover:scale-110 fil-gray-400"
                            />
                        </div>

                        <div className="form grid-cols-1">
                            <div className="flex flex-col">
                                <div className="flex gap-4">
                                    <input
                                        className="input"
                                        type="date"
                                        value={moment(start).format(
                                            "yyyy-MM-DD"
                                        )}
                                        onChange={(args) =>
                                            changeDate(args.target.value, true)
                                        }
                                        min={moment(new Date()).format(
                                            "yyyy-MM-DD"
                                        )}
                                    />
                                    <input
                                        className="input"
                                        type="time"
                                        value={moment(start).format("HH:mm")}
                                        onChange={(arg) =>
                                            changeTime(arg.target.value, true)
                                        }
                                    />
                                </div>
                                <IconNext className="self-center fill-black rotate-90" />
                                <div className="flex gap-4">
                                    <input
                                        className="input"
                                        type="date"
                                        value={moment(end).format("yyyy-MM-DD")}
                                        onChange={(args) =>
                                            changeDate(args.target.value, false)
                                        }
                                        min={moment(new Date()).format(
                                            "yyyy-MM-DD"
                                        )}
                                    />
                                    <input
                                        className="input"
                                        type="time"
                                        value={moment(end).format("HH:mm")}
                                        onChange={(arg) =>
                                            changeTime(arg.target.value, false)
                                        }
                                    />
                                </div>
                            </div>
                            <label className="label">Locatie</label>
                            <select
                                className="input"
                                value={location}
                                onChange={(args) =>
                                    setLocation(args.target.value)
                                }
                            >
                                <option value="Antwerpen">
                                    Antwerpen
                                </option>
                                <option value="Gent">Gent</option>
                            </select>
                            <div className="flex gap-4">
                                {timeslot && (
                                    <IconDelete
                                        onClick={() => setShowDelete(true)}
                                        className="fill-gray-800 cursor-pointer
      self-center transition duration-200 hover:scale-110"
                                    />
                                )}
                                <ButtonSecondary
                                    className="w-full"
                                    onClick={onSubmit}
                                >
                                    {editingSlot ? "Wijzigen" : "Vastleggen"}
                                </ButtonSecondary>
                            </div>
                        </div>
                    </FadeIn>
                )}
                {showDelete && (
                    <FadeIn>
                        <div className="flex flex-col gap-4">
                            <h3 className="flex gap-2">
                                <IconDelete className="self-center fill-red-500" />{" "}
                                Tijdslot verwijderen
                            </h3>
                            <span>
                                Weet je zeker dat je deze tijdslot wilt
                                verwijderen?
                            </span>
                            <div className="flex gap-3">
                                <ButtonSecondary
                                    className="bg-gray-200 text-black"
                                    onClick={() => setShowDelete(false)}
                                >
                                    Behouden
                                </ButtonSecondary>
                                <ButtonSecondary
                                    className="bg-red-500"
                                    onClick={() => {
                                        deleteSlot(editingSlot?.id ?? -1);
                                        setShowDelete(false);
                                    }}
                                >
                                    Verwijderen
                                </ButtonSecondary>
                            </div>
                        </div>
                    </FadeIn>
                )}
            </form>
        </SlideFromTop>
    );
};

export default TimeSlotForm;
