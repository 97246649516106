import React from "react";
import { useNavigate } from "react-router-dom";
import { ButtonPrimary } from "../components/button";

const InsufficientPrivileges = () => {
    const navigate = useNavigate();
    return (
        <div className="container">
            <div className="bg-red-300 p-6 rounded text-white font-bold flex flex-col justify-center items-center gap-4">
                <span>
                    U heeft niet genoeg rechten om deze pagina te bekijken.
                </span>
                <ButtonPrimary
                    className="flex-grow-0"
                    onClick={() => navigate("/")}
                >
                    Terug naar overzicht
                </ButtonPrimary>
            </div>
        </div>
    );
};

export default InsufficientPrivileges;
