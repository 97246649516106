import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import {
    createTimeSlot,
    deleteTimeSlot,
    getTimeSlots,
    updateTimeSlot
} from "../../services/TimeslotService";
import { TimeSlot, TimeSlotPost } from "../../interfaces";

const useTimeSlots = (userId: number) => {
    const [currentSlot, setCurrentSlot] = useState<TimeSlot | null>(null);
    const client = useQueryClient();
    const data = useQuery(["timeslots", userId], () => getTimeSlots(userId), {
        enabled: userId !== -1
    });
    const create = useMutation(
        (timeSlot: TimeSlotPost) => createTimeSlot(timeSlot),
        {
            onSuccess: () => client.invalidateQueries(["timeslots"])
        }
    );

    const update = useMutation(
        (timeSlot: TimeSlot) => updateTimeSlot(timeSlot),
        {
            onSuccess: () => client.invalidateQueries(["timeslots"])
        }
    );
    const deleteSlot = useMutation((id: number) => deleteTimeSlot(id), {
        onSuccess: () => client.invalidateQueries(["timeslots"])
    });
    const selectSlot = (id: number) => {
        const selectedSlot = data?.data?.find((slot) => slot.id === id);
        setCurrentSlot(selectedSlot || null);
    };
    const unselectSlot = () => {
        setCurrentSlot(null);
    };
    return {
        timeslots: data,
        create,
        update,
        currentSlot,
        selectSlot,
        unselectSlot,
        deleteSlot
    };
};

export default useTimeSlots;
