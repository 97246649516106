import React from "react";
import { AnimatePresence } from "framer-motion";
import { Section } from "../../components/ui";
import { useUser } from "../../contexts";
import useMeetings from "./useMeetings";
import { LoadingCards } from "../../components/card";
import { FadeIn } from "../../components/animation";
import MeetingsList from "./MeetingsList";

export function Home() {
    const { user } = useUser();
    const { data } = useMeetings(user);

    return (
        <div className="container">
            <Section>
                <h1>
                    Welkom terug,{" "}
                    <span className="text-xl font-semibold ">
                        {user?.fullname || "volledige naam.."} 👋
                    </span>
                </h1>

                <AnimatePresence>
                    {data.isLoading ||
                        (data.isFetching && (
                            <FadeIn>
                                <LoadingCards size={3} />
                            </FadeIn>
                        ))}
                    {!data.isLoading && !data.isFetching && (
                        <FadeIn>
                            <MeetingsList meetings={data.data ?? []} />
                        </FadeIn>
                    )}
                </AnimatePresence>

                {/* <h2>Opkomende meetings</h2> */}
                {/*                    <ScrollX>
                        <div className="card-list sm:h-[320px]">
                            {multipleFilterMeetingsByUser([
                                "proposed",
                                "planned"
                            ]) ? (
                                multipleFilterMeetingsByUser([
                                    "proposed",
                                    "planned"
                                ]).map((meeting: MeetingOld, index: number) => (
                                    <Card
                                        clickable
                                        confirmed={false}
                                        key={index}
                                        attendees={[
                                            {
                                                fullname: "George",
                                                avatar: "",
                                                function: ""
                                            }
                                        ]}
                                        type={
                                            (meeting.type &&
                                                meeting.type.name?.toLowerCase()) ||
                                            "recapgesprek"
                                        }
                                        slot={{
                                            start:
                                                meeting.startTime || new Date(),
                                            end: meeting.endTime || new Date()
                                        }}
                                        date={meeting.startTime}
                                        location={meeting.location}
                                    />
                                ))
                            ) : (
                                <Alert
                                    text="Waarschuwing"
                                    className="w-full"
                                >
                                    Gebruiker heeft nog geen uitnodiging gehad
                                    om een meeting aan te maken.
                                </Alert>
                            )}
                        </div>
                    </ScrollX>

                    <Card
                        attendees={[
                            { fullname: "George", avatar: "", function: "" }
                        ]}
                        type="loon"
                        date="Maandag"
                        location="gent"
                    />
                    <Card
                        attendees={[
                            { fullname: "George", avatar: "", function: "" }
                        ]}
                        type="recap"
                        date="Maandag"
                        location="antwerpen"
                    /> */}
                {/* <div className="group grid gap-4"> */}
                {/*     <div className="card "> */}
                {/*         <div */}
                {/*             className="transition-all absolute h-1/3 top-1/3 -translate-x-[2px] left-0  w-[4px] bg-gray-300 group-hover:bg-orange-500 flex rounded-full" /> */}
                {/*         <h3>Loongesprek</h3> */}
                {/*         <div> */}
                {/*             <h4>Aanwezigheden</h4> */}
                {/*             <p>Maxime</p> */}
                {/*         </div> */}
                {/*         <div className="flex -space-x-2 overflow-hidden"> */}
                {/*             <img */}
                {/*                 className="inline-block h-8 w-8 rounded-full ring-2 ring-white" */}
                {/*                 src="https://images.unsplash.com/photo-1491528323818-fdd1faba62cc?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" */}
                {/*                 alt="" */}
                {/*             /> */}
                {/*             <img */}
                {/*                 className="inline-block h-8 w-8 rounded-full ring-2 ring-white" */}
                {/*                 src="https://images.unsplash.com/photo-1550525811-e5869dd03032?ixlib=rb-1.2.1&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" */}
                {/*                 alt="" */}
                {/*             /> */}
                {/*             <img */}
                {/*                 className="inline-block h-8 w-8 rounded-full ring-2 ring-white" */}
                {/*                 src="https://images.unsplash.com/photo-1500648767791-00dcc994a43e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2.25&w=256&h=256&q=80" */}
                {/*                 alt="" */}
                {/*             /> */}
                {/*             <img */}
                {/*                 className="inline-block h-8 w-8 rounded-full ring-2 ring-white" */}
                {/*                 src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80" */}
                {/*                 alt="" */}
                {/*             /> */}
                {/*         </div> */}
                {/*         <div className="mt-4 flex justify-between gap-6"> */}
                {/*             <div> */}
                {/*                 <h4>Datum</h4> */}
                {/*                 <p>Dinsdag 12 maart</p> */}
                {/*             </div> */}
                {/*             <div className="text-right"> */}
                {/*                 <h4>Tijdslot</h4> */}
                {/*                 <p>14h00 - 15h00</p> */}
                {/*             </div> */}
                {/*         </div> */}
                {/*     </div> */}
                {/*     <div className="flex justify-between"> */}
                {/*         <button className="button-primary"> */}
                {/*             Wijzig meeting */}
                {/*         </button> */}
                {/*         <button className="button-secondary"> */}
                {/*             Go to overview */}
                {/*         </button> */}
                {/*     </div> */}
                {/* </div> */}
            </Section>

            {/*                 <Section>
                    <div className="mt-12">
                        <Partners />
                    </div>
                </Section> */}
        </div>
    );
}

export default Home;
