import { FC, SVGProps } from "react";

interface IconMoneyProps extends SVGProps<SVGSVGElement> {}

const IconMoney: FC<IconMoneyProps> = (props) => (
    <svg
        width={30}
        height={18}
        viewBox="0 0 30 18"
        fill="black"
        xmlns="http://www.w3.org/2000/svg"
        fillOpacity={0.1}
        {...props}
    >
        <path d="M14.9359 3.73397C12.4612 3.73397 10.4551 6.07424 10.4551 8.96153C10.4551 11.8484 12.4612 14.1891 14.9359 14.1891C17.4096 14.1891 19.4166 11.8493 19.4166 8.96153C19.4166 6.07424 17.4106 3.73397 14.9359 3.73397ZM16.8029 11.5753C16.8029 11.7816 16.6358 11.9487 16.4295 11.9487H13.4423C13.236 11.9487 13.0689 11.7816 13.0689 11.5753V10.8285C13.0689 10.6222 13.236 10.4551 13.4423 10.4551H14.1891V7.86747L14.1671 7.88194C14.1264 7.90916 14.0806 7.92807 14.0325 7.93759C13.9844 7.94712 13.9349 7.94706 13.8869 7.93744C13.8388 7.92782 13.7931 7.90881 13.7524 7.88151C13.7116 7.85421 13.6767 7.81914 13.6495 7.77833L13.2351 7.15709C13.2078 7.11631 13.1889 7.07056 13.1794 7.02247C13.1699 6.97437 13.1699 6.92487 13.1796 6.8768C13.1892 6.82872 13.2082 6.78301 13.2355 6.74229C13.2628 6.70157 13.2979 6.66663 13.3387 6.63947L14.0542 6.16245C14.2382 6.03979 14.4543 5.97434 14.6754 5.97435H15.3093C15.5156 5.97435 15.6827 6.14145 15.6827 6.34775V10.4551H16.4295C16.6358 10.4551 16.8029 10.6222 16.8029 10.8285V11.5753ZM28.3782 0H1.49359C0.668847 0 0 0.668847 0 1.49359V16.4295C0 17.2542 0.668847 17.9231 1.49359 17.9231H28.3782C29.2029 17.9231 29.8718 17.2542 29.8718 16.4295V1.49359C29.8718 0.668847 29.2029 0 28.3782 0ZM27.6314 12.6955C25.9814 12.6955 24.6442 14.0327 24.6442 15.6827H5.22756C5.22756 14.0327 3.89033 12.6955 2.24038 12.6955V5.22756C3.89033 5.22756 5.22756 3.89033 5.22756 2.24038H24.6442C24.6442 3.89033 25.9814 5.22756 27.6314 5.22756V12.6955Z" />
    </svg>
);

export default IconMoney;
