import React, { FC } from "react";

interface LoadingRowsProps {
    size: number;
}

export const LoadingRows: FC<LoadingRowsProps> = ({ size }) => {
    const rows = [];

    for (let i = 0; i < size; i++) {
        rows.push(
            <tr key={i}>
                <td
                    className="animate-pulse"
                    colSpan={5}
                >
                    <div className="h-4 bg-gray-200 mt-3 rounded" />
                </td>
            </tr>
        );
    }
    return <tbody className="text-gray-600 text-sm font-light">{rows}</tbody>;
};
export default LoadingRows;
