import { FC, SVGProps } from "react";

interface IconLogoutProps extends SVGProps<SVGSVGElement> {}

const IconLogout: FC<IconLogoutProps> = (props) => (
    <svg
        width={20}
        height={16}
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
    >
        <path
            d="M16.25 15H12.9688C12.7109 15 12.5 14.7891 12.5 14.5312V12.9688C12.5 12.7109 12.7109 12.5 12.9688 12.5H16.25C16.9414 12.5 17.5 11.9414 17.5 11.25V3.75C17.5 3.05859 16.9414 2.5 16.25 2.5H12.9688C12.7109 2.5 12.5 2.28906 12.5 2.03125V0.46875C12.5 0.210938 12.7109 0 12.9688 0H16.25C18.3203 0 20 1.67969 20 3.75V11.25C20 13.3203 18.3203 15 16.25 15ZM14.4141 7.14844L7.85156 0.585938C7.26562 0 6.25 0.410156 6.25 1.25V5H0.9375C0.417969 5 0 5.41797 0 5.9375V9.6875C0 10.207 0.417969 10.625 0.9375 10.625H6.25V14.375C6.25 15.2148 7.26562 15.625 7.85156 15.0391L14.4141 8.47656C14.7773 8.10938 14.7773 7.51562 14.4141 7.14844Z"
            fill="black"
        />
    </svg>
);

export default IconLogout;
