import { FC, useState } from "react";
import moment from "moment";
import { AnimatePresence } from "framer-motion";
import { FadeIn, SlideFromTop } from "../animation";
import { ButtonPrimary, ButtonSecondary } from "../button";
import { TimeSlot, User } from "../../interfaces";
import {
    IconClock,
    IconDatePicker,
    IconDelete,
    IconEdit,
    IconLocation,
    IconMoney,
    IconSpeaking
} from "../icons";
import { Avatar } from "../avatar";

interface CardProps {
    attendees: User[] | undefined;
    timeslot: TimeSlot | null;
    type?: string;
    clickable?: boolean;
    size?: "sm" | "md" | "lg";
    hoverable?: boolean;
    onClick?: () => void;
    onClickView?: () => void;
    onClickEdit?: () => void;
    onClickDelete?: () => void;
    onClickAccept?: () => void;
    onClickDeny?: () => void;
}

export const Card: FC<CardProps> = ({
    attendees = [],
    timeslot,
    type = "RECAP",
    clickable = true,
    size = "md",
    onClick,
    onClickView,
    onClickEdit,
    onClickDelete,
    onClickAccept,
    onClickDeny,
    hoverable
}) => {
    const [hover, setHover] = useState(false);
    return (
        <div
            className="grid items-start"
            onMouseOver={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
        >
            <div
                className={`card ${
                    size === "sm" ? "card-sm" : ""
                } z-10 cursor-auto`}
            >
                {clickable && (
                    <div className="transition-all absolute h-1/3 top-1/3 -translate-x-[2px] left-0  w-[4px] bg-gray-300 group-hover:bg-orange-500 flex rounded-full" />
                )}
                <h3 className="flex items-center">
                    {(type?.toUpperCase() === "RECAP" && "Recapgesprek") ||
                        (type?.toUpperCase() === "SALARY" && "Loongesprek")}
                </h3>
                {type === "Recap" && (
                    <IconSpeaking className="absolute right-5 top-5" />
                )}
                {type === "Salary" && (
                    <IconMoney className="absolute right-5 top-5" />
                )}
                <div className="flex flex-col gap-2">
                    <h4>Aanwezigen</h4>
                    {attendees?.map((attendee) => (
                        <Avatar
                            key={attendee.id}
                            showFunction={false}
                            person={attendee}
                        />
                    ))}
                </div>
                <div
                    className={`mt-4 grid justify-between  text-black/75 ${
                        size === "sm" ? " text-xs gap-1" : "text-sm"
                    } `}
                >
                    <div className="flex items-center justify-start space-x-2">
                        <span className="flex items-center justify-center h-4 w-4">
                            <IconLocation className="fill-black/20" />{" "}
                        </span>
                        <span className="capitalize">
                            {timeslot?.location || "Geen locatie"}
                        </span>
                    </div>
                    <div className="flex items-center justify-start space-x-2">
                        <span className="flex items-center justify-center h-4 w-4">
                            <IconDatePicker className="fill-black/20" />
                        </span>
                        {size === "sm" ? (
                            <span>
                                {timeslot
                                    ? moment(new Date(timeslot.start)).format(
                                          "L"
                                      )
                                    : "Geen datum"}
                            </span>
                        ) : (
                            <span>
                                {timeslot
                                    ? moment(new Date(timeslot.end)).format(
                                          "dddd LL"
                                      )
                                    : "Geen datum"}
                            </span>
                        )}
                    </div>
                    <div className="flex items-center justify-start space-x-2">
                        <span className="flex items-center justify-center h-4 w-4">
                            <IconClock className="stroke-black/20" />
                        </span>
                        <span>
                            {timeslot
                                ? `${moment(new Date(timeslot.start)).format(
                                      "LT"
                                  )} - ${moment(new Date(timeslot.end)).format(
                                      "LT"
                                  )}`
                                : "Geen tijdslot"}
                        </span>
                    </div>
                </div>
            </div>
            {clickable && hoverable && (
                <AnimatePresence>
                    {hover && (
                        <SlideFromTop>
                            <FadeIn>
                                {(!!onClickEdit ||
                                    !!onClickView ||
                                    !!onClickDelete) && (
                                    <div className="flex justify-between mt-4 z-1 relative">
                                        <div className="grid grid-flow-col items-center justify-start gap-2">
                                            <ButtonPrimary
                                                onClick={onClickEdit}
                                            >
                                                <IconEdit className="fill-white" />
                                            </ButtonPrimary>
                                            <ButtonPrimary
                                                onClick={onClickDelete}
                                            >
                                                <IconDelete className="stroke-white" />
                                            </ButtonPrimary>
                                        </div>
                                        <div>
                                            <ButtonSecondary
                                                onClick={onClickView}
                                            >
                                                Naar overzicht
                                            </ButtonSecondary>
                                        </div>
                                    </div>
                                )}
                                {!!onClickAccept && !!onClickDeny && (
                                    <div className="grid grid-cols-2 gap-2 mt-4 z-1 relative">
                                        <ButtonPrimary
                                            onClick={onClickDeny}
                                            className="w-full"
                                        >
                                            Verwijder
                                        </ButtonPrimary>
                                        <ButtonSecondary
                                            onClick={onClickAccept}
                                            className="w-full"
                                        >
                                            Bevestig
                                        </ButtonSecondary>
                                    </div>
                                )}
                            </FadeIn>
                        </SlideFromTop>
                    )}
                </AnimatePresence>
            )}
        </div>
    );
};
export default Card;
