import React, { FC } from "react";
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";

interface PrivateRouteProps {
    children: React.ReactNode;
}

export const PrivateRoute: FC<PrivateRouteProps> = ({ children }) => (
    <MsalAuthenticationTemplate
        children={children}
        interactionType={InteractionType.Redirect}
    />
);
export default PrivateRoute;
