import { useState } from "react";
import { UploadCsvForm } from "../components/form";
import { Section } from "../components/ui";
import { UserManagement } from "../components/usermanagement";

export const AdminPanel = () => {
    const [showForm, setShowForm] = useState(false);

    return (
        <div className="container">
            <Section>
                <div className="flex justify-between">
                    <h1>Overzicht</h1>
                    <button
                        className="button-round"
                        disabled={showForm}
                        onClick={() => setShowForm(true)}
                    >
                        Gebruikers importeren
                    </button>
                </div>
                {showForm && <UploadCsvForm setShow={setShowForm} />}
                <div>
                    <UserManagement />
                </div>
            </Section>
        </div>
    );
};

export default AdminPanel;
