import axios from "axios";
import { requestToken } from "./msal";
import { AddMeetingType, Meeting, TimeRange } from "../interfaces";

export const getMeetingsAsSubject = async () => {
    const request = await requestToken();
    const response = await axios.get<Meeting[]>(
        `/Meeting/getMeetingsAsSubject`,
        {
            headers: {
                Authorization: `Bearer ${request.accessToken}`
            }
        }
    );
    return response.data;
};

export const getMeetingsAsPartner = async () => {
    const request = await requestToken();
    const response = await axios.get<Meeting[]>(
        `/Meeting/getMeetingsAsPartner`,
        {
            headers: {
                Authorization: `Bearer ${request.accessToken}`
            }
        }
    );
    return response.data;
};

export const addMeeting = async (addMeetingType: AddMeetingType) => {
    const request = await requestToken();

    await axios.post(
        `meeting/AddMeeting`,
        {},
        {
            headers: {
                Authorization: `Bearer ${request.accessToken}`
            },
            params: {
                startDateTime: addMeetingType.startDateTime,
                userId: addMeetingType.userId,
                location: addMeetingType.locationString
            }
        }
    );
};
export const getAvailableMeetingTimes = async (targetDate: Date) => {
    const request = await requestToken();

    const response = await axios.get<TimeRange[]>(
        "meeting/GetAvailableMeetingTimes",
        {
            headers: {
                Authorization: `Bearer ${request.accessToken}`
            },
            params: {
                targetDate
            }
        }
    );
    return response.data;
};
