import { Resolver, useForm } from "react-hook-form";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { FC } from "react";
import { getUsers, uploadCsv } from "../../services/UserService";
import { Section } from "../ui";
import { ButtonLoading, ButtonRound } from "../button";
import { IconUpload } from "../icons";
import { AlertAnimated } from "../alert";

type FormValues = {
    file: FileList;
};

interface UploadCsvFormProps {
    setShow: (show: boolean) => void;
}

const resolver: Resolver<FormValues> = async (values) => {
    let errors = {};
    if (!values.file[0]) {
        errors = {
            file: {
                type: "required",
                message: "Gelieve een bestand te selecteren"
            }
        };
    }
    if (values.file[0]) {
        values.file[0].type !== "text/csv"
            ? (errors = {
                  file: {
                      type: "filetype",
                      message: "Gelieve een csv bestand te selecteren"
                  }
              })
            : {};
    }
    return {
        values: values.file ? values : {},
        errors
    };
};

export const UploadCsvForm: FC<UploadCsvFormProps> = ({ setShow }) => {
    const {
        register,
        handleSubmit,
        watch,
        formState: { errors }
    } = useForm<FormValues>({
        resolver
    });
    // const [toastId, setToastId] = useState<Id | null>(null);
    const watchFile = watch("file");
    const client = useQueryClient();
    useQuery(["users"], getUsers);
    const onSuccessMutation = () => {
        client.refetchQueries(["users"]);
        setShow(false);
    };
    const mutation = useMutation(
        (formData: FormValues) => uploadCsv(formData.file[0]),
        {
            onSuccess: onSuccessMutation
        }
    );
    // useEffect(() => {
    //     setToastId(toast.loading("Even wachten..."));
    //     if (toastId && mutation.isSuccess) {
    //         toast.update(toastId, {
    //             render: "Gebruiker verwijderd",
    //             type: "success",
    //             isLoading: false,
    //             autoClose: 2000
    //         });
    //     }
    // }, [mutation.isSuccess]);
    const onFormSubmit = handleSubmit((data) => mutation.mutate(data));
    return (
        <Section>
            <h2>Gebruikers uploaden</h2>
            <form className="tabs grid gap-4" onSubmit={onFormSubmit}>
                <div className="flex justify-center items-center w-full">
                    <label
                        htmlFor="dropzone-file"
                        className="flex flex-col justify-center items-center w-full h-64 bg-gray-50 rounded-sm border-2 border-gray-300 border-dashed cursor-pointer hover:bg-gray-100"
                    >
                        <div className="flex flex-col justify-center items-center pt-5 pb-6  ">
                            <IconUpload />
                            <p className="mb-2 text-sm text-gray-500">
                                <span className="font-semibold">
                                    Bestand kiezen
                                </span>{" "}
                                of gebruik slepen en neerzetten
                            </p>
                            <p className="text-xs text-gray-500">
                                Enkel CSV toegelaten
                            </p>
                            {watchFile && watchFile[0] && (
                                <span className="font-semibold mt-2 text-orange-500">
                                    {watchFile[0].name}
                                </span>
                            )}
                        </div>
                        <input
                            id="dropzone-file"
                            type="file"
                            className="hidden"
                            {...register("file", { required: true })}
                        />
                    </label>
                </div>

                {errors?.file && (
                    <AlertAnimated text="Waarschuwing">
                        {errors.file.message}
                    </AlertAnimated>
                )}

                {mutation.isError && (
                    <AlertAnimated text="Error">
                        Er is iets fout gegaan
                    </AlertAnimated>
                )}

                {mutation.isSuccess && (
                    <AlertAnimated text="Succes">
                        Je bestand is opgeladen
                    </AlertAnimated>
                )}

                <div className="flex justify-end">
                    <button
                        onClick={() => setShow(false)}
                        className="bg-black/20 mr-4 text-white text-xs font-semibold hover:opacity-70 transition-all rounded-full text-xs font-semibold uppercase px-8 py-3 "
                    >
                        Sluiten
                    </button>
                    {mutation.isLoading ? (
                        <ButtonLoading />
                    ) : (
                        <ButtonRound>Uploaden</ButtonRound>
                    )}
                </div>
            </form>
        </Section>
    );
};
export default UploadCsvForm;
