import React, { FC } from "react";
import { LoadingCard } from "./index";

interface LoadingCardsProps {
    size: number;
}

const LoadingCards: FC<LoadingCardsProps> = ({ size }) => {
    const cards = [];
    for (let i = 0; i < size; i++) {
        cards.push(
            <LoadingCard key={i} />
        );
    }

    return (
        <div className="card-list">
            {cards}
        </div>
    );

};

export default LoadingCards;
