import { TimeSlotOld, User } from ".";

export enum MeetingType {
    Recap,
    Salary
}

export interface Meeting {
    id: number;
    type: string;
    start: Date;
    end: Date;
    location: string;
    subject: User;
    partners: User[];
}

export interface MeetingOld {
    date?: TimeSlotOld;

    people?: {
        consultant?: string;
        hr?: string[];
        league?: string[];
        captain?: string[];
        other?: string[];
    };

    meetingId?: string;
    userInMeeting?: UserInMeeting[];
    startTime?: Date | string;
    endTime?: Date;
    endBuffer?: number;
    status?: string | "Proposed" | "Planned" | "Confirmed";
    location?: string;
    type?: MeetingType;
}

export interface Type {
    typeId?: string;
    name?: string;
    duration?: number;
}

export interface UserInMeeting {
    userId?: string;
    confirmed?: boolean;
    role?: string;
    user?: User;
}
