import { FC, SVGProps } from "react";

interface IconNextProps extends SVGProps<SVGSVGElement> {}

const IconNext: FC<IconNextProps> = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width="22"
        height="22"
        fill="none"
        viewBox="0 0 22 22"
        {...props}
    >
        <path d="M8.534 6.15a.913.913 0 0 0 0 1.293L12.091 11l-3.557 3.557a.913.913 0 1 0 1.293 1.292l4.207-4.207a.913.913 0 0 0 0-1.293L9.827 6.142a.921.921 0 0 0-1.293.009Z" />
    </svg>
);

export default IconNext;
