import { useNavigate } from "react-router-dom";
import { IconAdd } from "../icons";

export const CardAdd = () => {
    const navigate = useNavigate();
    return (
        <div
            onClick={() => navigate(`meeting/new`)}
            className="group card items-center justify-center"
        >
            <div className="transition-all absolute h-1/3 top-1/3 -translate-x-[2px] left-0  w-[4px] bg-gray-300 group-hover:bg-orange-500 flex rounded-full" />
            <div className="grid items-center text-center py-8">
                <IconAdd className="mx-auto" />
                <span className="mt-3 text-sm font-semibold">Plan meeting</span>
                <span className="text-xs">Voeg loon- en recapgesprek toe.</span>
            </div>
        </div>
    );
};
export default CardAdd;
