import React, { FC, ReactNode } from "react";
import { AnimatePresence } from "framer-motion";
import { Alert } from "./Alert";
import { FadeIn } from "../animation/FadeIn";
import { SlideFromTop } from "../animation/SlideFromTop";

interface AlertAnimatedProps {
    text?: string;
    children?: ReactNode;
}

export const AlertAnimated: FC<AlertAnimatedProps> = ({
    text = "Waarschuwing",
    children
}) => (
    <AnimatePresence>
        <SlideFromTop>
            <FadeIn>
                <Alert text={text}>{children}</Alert>
            </FadeIn>
        </SlideFromTop>
    </AnimatePresence>
);

export default AlertAnimated;
