import { useEffect, useState } from "react";
import { TimeSlot } from "../../interfaces";

const useTimeSlotForm = (startDate: Date, endDate: Date, timeSlot: TimeSlot | null) => {
    const [start, setStart] = useState(new Date(startDate));
    const [end, setEnd] = useState(new Date(endDate));
    const [location, setLocation] = useState<string>('Antwerpen');
    const [timeslot, setTimeslot] = useState<TimeSlot | null>(null);
    const [showDelete, setShowDelete] = useState(false);

    const changeDate = (newDate: string, isStart: boolean) => {
        const original = isStart ? start : end;
        const date = new Date(newDate);
        date.setHours(original.getHours());
        date.setMinutes(original.getMinutes());
        if (isStart) setStart(date);
        else setEnd(date);
    };

    const changeTime = (newTime: string, startTime: boolean) => {
        const parts = newTime.split(":");
        const hours = parseInt(parts[0], 10);
        const minutes = parseInt(parts[1], 10);
        if (startTime) {
            setStart((prev) => {
                prev.setHours(hours);
                prev.setMinutes(minutes);
                return new Date(prev);
            });
        } else {
            setEnd((prev) => {
                prev.setHours(hours);
                prev.setMinutes(minutes);
                return new Date(prev);
            });
        }
    };

    useEffect(() => {
        setStart(new Date(startDate));
    }, [startDate]);

    useEffect(() => {
        setEnd(new Date(endDate));
    }, [endDate]);

    useEffect(() => {
        setTimeslot(timeSlot);
        setShowDelete(false);
        if (timeSlot) {
            setStart(new Date(timeSlot.start));
            setEnd(new Date(timeSlot.end));
            setLocation(timeSlot.location);
        }
    }, [timeSlot]);

    return { start, end, location, changeDate, changeTime, setLocation, timeslot, showDelete, setShowDelete };
};

export default useTimeSlotForm;
