import * as msal from "@azure/msal-browser";
import { Configuration, InteractionStatus, IPublicClientApplication } from "@azure/msal-browser";

export const loginRequest = {
    scopes: ["openid", "profile", "offline_access"]
};

export const tokenRequest = {
    scopes: ["api://981760c0-65b0-4215-ac98-323afb1e92ca/read"]
};

export const msalConfig: Configuration = {
    auth: {
        clientId: process.env.REACT_APP_PUBLIC_AZURE_AD_CLIENT_ID ?? "",
        authority: `https://login.microsoftonline.com/${process.env.REACT_APP_PUBLIC_AZURE_AD_TENANT_ID}`,
        redirectUri: "/",
        postLogoutRedirectUri: "/"
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
    system: {}
};
export const signOutClickHandler = async (
    instance: IPublicClientApplication
) => {
    await instance.logoutRedirect();
};

export const loginClickHandler = async (
    instance: IPublicClientApplication,
    isAuthenticated: boolean,
    inProgress: InteractionStatus,
    interactionStatus: InteractionStatus
) => {
    if (!isAuthenticated && inProgress === interactionStatus) {
        const accounts = instance.getAllAccounts();
        if (accounts.length === 0) {
            await instance.loginRedirect(loginRequest);
        }
    }
};

export const instance = new msal.PublicClientApplication(msalConfig);

export const initialiseMsal = () => {
    const accounts = instance.getAllAccounts();
    if (accounts.length > 0) {
        instance.setActiveAccount(accounts[0]);
    }
    return instance;
};

export const requestToken = async () => {
    const accounts = instance.getAllAccounts();
    const request = {
        ...tokenRequest,
        account: accounts[0]
    };
    try {
        return await instance.acquireTokenSilent(request);
    } catch (error) {
        return await instance.acquireTokenPopup(request);
    }
};

export const requestGraphToken = async () => {
    const accounts = instance.getAllAccounts();

    const request = {
        ...loginRequest,
        account: accounts[0]
    };
    try {
        return await instance.acquireTokenSilent(request);
    } catch (error) {
        return await instance.acquireTokenPopup(request);
    }
};
