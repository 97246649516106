import { FC, SVGProps } from "react";

interface IconPeopleProps extends SVGProps<SVGSVGElement> {}

const IconPeople: FC<IconPeopleProps> = (props) => (
    <svg
        width={25}
        height={21}
        viewBox="0 0 14 11"
        xmlns="http://www.w3.org/2000/svg"
        fill="black"
        {...props}
    >
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M6.125 10.5C6.125 10.5 5.25 10.5 5.25 9.625C5.25 8.75 6.125 6.125 9.625 6.125C13.125 6.125 14 8.75 14 9.625C14 10.5 13.125 10.5 13.125 10.5H6.125ZM9.625 5.25C10.3212 5.25 10.9889 4.97344 11.4812 4.48116C11.9734 3.98887 12.25 3.32119 12.25 2.625C12.25 1.92881 11.9734 1.26113 11.4812 0.768845C10.9889 0.276562 10.3212 0 9.625 0C8.92881 0 8.26113 0.276562 7.76884 0.768845C7.27656 1.26113 7 1.92881 7 2.625C7 3.32119 7.27656 3.98887 7.76884 4.48116C8.26113 4.97344 8.92881 5.25 9.625 5.25V5.25ZM4.564 10.5C4.43429 10.2268 4.3696 9.92735 4.375 9.625C4.375 8.43937 4.97 7.21875 6.069 6.37C5.52045 6.20098 4.94896 6.11833 4.375 6.125C0.875 6.125 0 8.75 0 9.625C0 10.5 0.875 10.5 0.875 10.5H4.564ZM3.9375 5.25C4.51766 5.25 5.07406 5.01953 5.4843 4.6093C5.89453 4.19906 6.125 3.64266 6.125 3.0625C6.125 2.48234 5.89453 1.92594 5.4843 1.5157C5.07406 1.10547 4.51766 0.875 3.9375 0.875C3.35734 0.875 2.80094 1.10547 2.3907 1.5157C1.98047 1.92594 1.75 2.48234 1.75 3.0625C1.75 3.64266 1.98047 4.19906 2.3907 4.6093C2.80094 5.01953 3.35734 5.25 3.9375 5.25V5.25Z"
        />
    </svg>
);

export default IconPeople;
