import React, {
    createContext,
    FC,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";
import { loginClickHandler, signOutClickHandler } from "../services/msal";
import { getMe } from "../services/UserService";
import { User } from "../interfaces";

interface UserContextProps {
    user: User | null;
    login: () => void;
    logout: () => void;
}

export const UserContext = createContext<UserContextProps>({
    user: null,
    login: () => {},
    logout: () => {}
});

interface UserProviderProps {
    children?: ReactNode;
}

const UserProvider: FC<UserProviderProps> = ({ children }) => {
    const { instance, inProgress, accounts } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const [user, setUser] = useState<User | null>(null);

    if (accounts[0] && !user) {
        setUser({
            id: -1,
            fullname: accounts[0].name ?? "Onbekende gebruiker",
            avatar: "",
            email: accounts[0].username ?? "Onbekend",
            isAdmin: false,
            hasPlannedMeeting: false
        });
    }

    const login = useCallback(() => {
        loginClickHandler(
            instance,
            isAuthenticated,
            inProgress,
            InteractionStatus.None
        );
    }, [inProgress, instance, isAuthenticated]);

    const logout = useCallback(() => {
        signOutClickHandler(instance);
    }, [instance]);

    useEffect(() => {
        if (accounts[0])
            getMe().then((res) => {
                setUser(res);
            });
    }, [accounts]);

    const value = useMemo(
        () => ({
            user,
            login,
            logout
        }),
        [login, logout, user]
    );

    return (
        <UserContext.Provider value={value}>{children}</UserContext.Provider>
    );
};

const useUser = () => useContext(UserContext);

export { UserProvider, useUser };
